import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledDiv = styled.div<{ step: number }>`
  margin: 0 auto;
  max-width: 1278px;
  padding: 30px 18px 0 18px;
  display: flex;
  justify-content: ${({ step }) => (step < 1 ? "flex-end" : "space-between")};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
