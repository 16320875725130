import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const ListIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 6.5C0.92 6.5 0.25 7.17 0.25 8C0.25 8.83 0.92 9.5 1.75 9.5C2.58 9.5 3.25 8.83 3.25 8C3.25 7.17 2.58 6.5 1.75 6.5ZM1.75 0.5C0.92 0.5 0.25 1.17 0.25 2C0.25 2.83 0.92 3.5 1.75 3.5C2.58 3.5 3.25 2.83 3.25 2C3.25 1.17 2.58 0.5 1.75 0.5ZM1.75 12.5C0.92 12.5 0.25 13.18 0.25 14C0.25 14.82 0.93 15.5 1.75 15.5C2.57 15.5 3.25 14.82 3.25 14C3.25 13.18 2.58 12.5 1.75 12.5ZM4.75 15H18.75V13H4.75V15ZM4.75 9H18.75V7H4.75V9ZM4.75 1V3H18.75V1H4.75Z"
      fill="white"
    />
  </svg>
);

export default ListIcon;
