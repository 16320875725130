import React, { FC } from "react";
import { StyledHeader, StyledParagraph, StyledTextContent } from "../styles";
import { ModalTextProps } from "../types";

const ModalTextMessage: FC<ModalTextProps> = ({ header, info }) => {
  return (
    <StyledTextContent>
      <StyledHeader>{header}</StyledHeader>
      <StyledParagraph>{info}</StyledParagraph>
    </StyledTextContent>
  );
};

export default ModalTextMessage;
