import React, { FC } from "react";
import { CheckboxProps } from "./types";
import { StyledDiv, StyledCheckbox, StyledLabel, StyledCheck } from "./styles";

const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  label,
  isChecked,
  onClick,
}) => {
  return (
    <StyledDiv>
      <StyledLabel htmlFor={name}>
        <StyledCheckbox
          onClick={onClick}
          type="checkbox"
          name={name}
          id={id}
        ></StyledCheckbox>
        {isChecked && <StyledCheck />}
        {label}
      </StyledLabel>
    </StyledDiv>
  );
};

export default Checkbox;
