import styled from "styled-components";

export const StyledHeaderSection = styled.section`
  margin: 79px 0 102px 0;
`;

export const StyledFormSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledFormDiv = styled.div`
  width: 1278px;
  height: auto;
  padding: 18px;
  display: block;
  background-color: ${({ theme }) => theme.colors.blue_100};
  border-radius: 12px;
`;

export const StyledHeader = styled.h2`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.055em;
`;

export const StyledParagraph = styled.p`
  margin-top: 18px;
  color: ${({ theme }) => theme.colors.gray_400};
  text-align: center;
  letter-spacing: 0.055em;
`;
