import React, { FC } from "react";
import { TechnologyListItemProps } from "./types";
import {
  StyledTechnologyListItem,
  StyledText,
  StyledButton,
  StyledCategoryIcon,
} from "./styles";

const TechnologyListItem: FC<TechnologyListItemProps> = ({
  technology,
  isChecked,
  onClick,
}) => {
  return (
    <StyledTechnologyListItem>
      <StyledButton
        isChecked={isChecked}
        onClick={() => onClick(technology.id)}
      >
        <StyledCategoryIcon isChecked={isChecked} />
        <StyledText>{technology.label}</StyledText>
      </StyledButton>
    </StyledTechnologyListItem>
  );
};
export default TechnologyListItem;
