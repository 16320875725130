import styled from "styled-components";
import { CategoryIcon } from "components/Icons/categoryIcon";
export const StyledTechnologyListItem = styled.li`
  padding: 8px 8px;
`;
export const StyledCategoryIcon = styled(CategoryIcon)<{ isChecked: boolean }>`
  padding: 0 7px 0 0;
  path {
    fill: ${(props) =>
      props.isChecked
        ? props.theme.colors.white
        : props.theme.colors.green_400};
  }
`;
export const StyledButton = styled.button<{ isChecked: boolean }>`
  height: 36px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isChecked ? props.theme.colors.green_400 : props.theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.green_400};
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  color: ${(props) =>
    props.isChecked ? props.theme.colors.white : props.theme.colors.green_400};
`;
export const StyledText = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  letter-spacing: 0.055em;
  font-weight: 600;
`;
