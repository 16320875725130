import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  BoldText,
  ImageElement,
  ItalicText,
  LineThoughText,
  ListItem,
  OrderedList,
  QuoteText,
  PreElement,
  LinkElement,
} from "../PDFMarkdownComponents";
import { RemarkOnPDFProps } from "./types";

const RemarkOnPDF: FC<RemarkOnPDFProps> = ({ dataToRemark }) => {
  return (
    <ReactMarkdown
      components={{
        a: ({ children, href }) => (
          <LinkElement href={href}>{children}</LinkElement>
        ),
        blockquote: ({ children }) => <QuoteText>{children}</QuoteText>,
        del: ({ children }) => <LineThoughText>{children}</LineThoughText>,
        em: ({ children }) => <ItalicText>{children}</ItalicText>,
        img: ({ src }) => <ImageElement src={src} />,
        li: ({ children, ...props }) => (
          <ListItem {...props}>{children}</ListItem>
        ),
        ol: ({ children }) => <OrderedList>{children}</OrderedList>,
        pre: ({ children }) => <PreElement>{children}</PreElement>,
        strong: ({ children }) => <BoldText>{children}</BoldText>,
      }}
      remarkPlugins={[remarkGfm]}
    >
      {dataToRemark}
    </ReactMarkdown>
  );
};

export default RemarkOnPDF;
