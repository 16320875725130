import React, { FC } from "react";
import ModalIcon from "components/Modal/ModalIcons";
import RethinkIcon from "components/Icons/RethinkIcon";
import ModalTextMessage from "components/Modal/ModalTextMessage";
import ModalButtons from "components/Modal/ModalButtons";
import { StyledButton } from "components/Modal/styles";
import { RethinkProps } from "./types";

const Rethink: FC<RethinkProps> = ({ onCancel, onConfirm }) => {
  return (
    <div>
      <ModalIcon>
        <RethinkIcon />
      </ModalIcon>
      <ModalTextMessage
        header="Czy na pewno chcesz usunąć całą listę?"
        info="Nie da się cofnąć tego działania."
      />
      <ModalButtons>
        <StyledButton kind="secondary" label="Nie" onClick={onCancel} />
        <StyledButton kind="warning" label="Tak, usuń" onClick={onConfirm} />
      </ModalButtons>
    </div>
  );
};

export default Rethink;
