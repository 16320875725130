import styled from "styled-components";
import Button from "components/Button";

export const StyledButton = styled(Button)`
  width: 166px;
  height: 48px;
  padding: 10px 5px;
  font-size: 20px;
`;

export const StyledLink = styled.a`
  margin: 0 0 20px 0;
  text-decoration: none;
`;

export const StyledDiv = styled.div`
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
