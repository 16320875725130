import React, { FC } from "react";
import { IconProps } from "../types";

const RethinkIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.7187 83.5312C25.1016 83.5312 3.9375 71.2266 3.9375 44.2266C3.9375 17.2266 25.1016 4.92188 44.7187 4.92188C55.6172 4.92188 65.6719 8.50781 73.125 15.0469C81.2109 22.2188 85.5 32.3438 85.5 44.2266C85.5 56.1094 81.2109 66.1641 73.125 73.3359C65.6719 79.875 55.5469 83.5312 44.7187 83.5312Z"
        fill="url(#paint0_radial_148_43642)"
      />
      <path
        d="M44.7187 83.5312C25.1016 83.5312 3.9375 71.2266 3.9375 44.2266C3.9375 17.2266 25.1016 4.92188 44.7187 4.92188C55.6172 4.92188 65.6719 8.50781 73.125 15.0469C81.2109 22.2188 85.5 32.3438 85.5 44.2266C85.5 56.1094 81.2109 66.1641 73.125 73.3359C65.6719 79.875 55.5469 83.5312 44.7187 83.5312Z"
        fill="url(#paint1_linear_148_43642)"
      />
      <path
        d="M78.391 20.8617C82.1387 26.9086 84.0934 34.1086 84.0934 42.1172C84.0934 54 79.8043 64.0547 71.7184 71.2266C64.2652 77.7656 54.1402 81.4219 43.3121 81.4219C30.6137 81.4219 17.2965 76.2539 9.43555 65.2922C17.0012 77.7094 31.2324 83.5313 44.7184 83.5313C55.5465 83.5313 65.6715 79.875 73.1246 73.3359C81.2105 66.1641 85.4996 56.1094 85.4996 44.2266C85.4996 35.2688 83.0598 27.3094 78.391 20.8617Z"
        fill="#EB8F00"
      />
      <path
        d="M4.47179 52.8047C3.1921 54.3586 5.9132 58.4859 6.39835 60.5883C7.56554 65.6649 6.39835 66.9164 6.30695 71.4305C6.2296 76.3945 7.7132 80.5711 11.496 83.2359C15.0538 85.7602 19.3429 87.0539 24.8273 85.4367C30.3116 83.8195 33.6444 82.0336 33.9257 79.3336C34.0804 78.2156 33.4898 77.1188 32.4632 76.6406C33.0116 76.3805 33.4476 75.9375 33.6937 75.382C35.0015 72.2602 32.3929 71.5992 32.3929 71.5992C32.7515 71.1703 33.0538 70.6992 33.2929 70.193C34.0734 68.6391 33.9468 66.5297 31.6968 65.8406C30.6632 65.5242 30.3819 65.693 30.3819 65.693C31.4507 64.5961 32.7304 63.7172 34.1437 63.1266C37.9265 61.5024 42.4124 60.518 43.5304 59.5688C45.1546 58.1625 45.253 55.4555 42.3843 54.6469C38.264 53.4375 30.6843 56.25 26.0718 59.182C21.4593 62.1141 17.2265 63.6961 15.8202 63.1617C14.414 62.6274 13.5421 61.7555 13.0077 59.6461C12.0163 55.7227 11.2499 54.2039 9.56945 52.7344C8.17726 51.5391 5.45617 51.6094 4.47179 52.8047Z"
        fill="url(#paint2_radial_148_43642)"
      />
      <path
        d="M45.1333 56.5172C44.6622 50.0766 31.9778 54.0563 25.502 58.2961C17.4512 63.5695 16.27 62.6344 15.4825 62.1422C14.5333 61.5516 13.9356 61.0594 13.4715 59.2313C12.4239 55.0969 12.1356 53.5781 10.2512 51.9328C8.67622 50.4774 3.93013 50.5125 3.61372 52.8188C3.26919 55.3008 4.67544 55.7648 5.62466 60.5391C6.79888 65.3766 6.32779 70.7203 6.32779 70.7203C6.32779 70.7203 8.52154 65.6227 6.41216 57.6774C5.98325 55.5328 3.94419 52.2492 7.3895 52.6219C10.7223 52.9805 10.9403 55.8844 11.9809 59.8992C12.5575 62.1703 13.1481 63.5063 15.4403 64.1391C17.7184 64.7649 22.9848 61.8399 26.7676 59.4352C31.4012 56.4891 38.2708 53.9156 41.8778 54.9703C43.4387 55.3078 44.4793 57.3258 42.8411 58.7531C40.2395 61.207 30.234 62.3109 24.2083 68.8289C24.2083 68.8289 24.9395 69.0961 26.0364 68.5547C27.1403 68.0133 31.2606 65.9742 31.2606 65.9742C32.4911 66.4945 33.0817 68.5406 32.4137 69.6305C31.3309 71.3953 27.1192 73.8352 25.0168 73.7297C25.0168 73.7297 24.9817 74.8055 27.0911 74.5172C27.759 74.4258 30.5223 73.8211 32.4559 72.3234C33.8973 73.3641 33.4895 75.1359 32.4067 76.4156C31.3309 77.6883 28.7012 79.3336 25.9942 79.6219C25.9942 79.6219 25.727 80.6484 27.5481 80.6274C29.3692 80.6063 31.345 79.6781 32.8004 78.5461C33.0184 78.8906 33.2223 79.643 32.9762 80.6766C32.5965 82.2727 30.452 83.8899 26.3106 84.7899C22.4926 85.6195 18.1895 86.2031 9.8856 81.893C9.8856 81.893 12.9864 85.9219 21.0793 86.6391C28.8418 87.3281 37.9051 83.8899 34.27 76.9922C34.27 76.9922 35.0575 75.825 35.1559 74.2219C35.2262 73.125 34.8817 71.6274 34.0731 70.9453C34.8325 68.7094 34.5934 67.1625 34.1083 66.2484C33.7848 65.6438 32.9692 65.0531 32.9692 65.0531C32.9692 65.0531 34.5793 63.5977 39.3254 62.3742C42.37 61.5797 45.4215 60.4547 45.1333 56.5172Z"
        fill="#EB8F00"
      />
      <path
        d="M13.1059 57.7477C13.1059 57.7477 16.502 63.2742 23.9833 59.2664C23.9833 59.2664 19.3919 64.0828 15.82 63.1617C12.2481 62.2406 13.1059 57.7477 13.1059 57.7477Z"
        fill="#EB8F00"
      />
      <path
        d="M34.4523 67.1766C34.4523 67.1766 36.182 63.4641 39.332 62.3602C39.332 62.3602 34.2133 62.7961 32.2656 64.8984L34.4523 67.1766Z"
        fill="#EB8F00"
      />
      <path
        d="M54.0496 50.1398C53.8246 50.0977 53.6136 50.0062 53.4379 49.8586C50.2683 47.316 46.4853 45.6525 42.4691 45.0352C38.4543 44.3391 34.334 44.7187 30.516 46.132C30.3051 46.2164 30.0801 46.2445 29.8551 46.2094C29.3629 46.125 28.9621 45.7594 28.8285 45.2742C28.6949 44.7961 28.8566 44.2898 29.2363 43.9734C36.6894 37.7859 47.7425 38.8125 53.93 46.2656C54.4363 46.8773 54.9004 47.5242 55.3222 48.1922C55.5894 48.607 55.5894 49.1414 55.3222 49.5562C55.048 49.9852 54.5488 50.2102 54.0496 50.1398Z"
        fill="#422B0D"
      />
      <path
        d="M56.792 21.8742C59.7381 21.8742 62.417 24.3633 62.417 28.4976C62.417 32.632 59.7381 35.114 56.792 35.114C53.8459 35.114 51.167 32.625 51.167 28.4976C51.167 24.3703 53.8459 21.8742 56.792 21.8742Z"
        fill="#422B0D"
      />
      <path
        d="M32.4424 15.4688C29.4963 15.4688 26.8174 17.9578 26.8174 22.0852C26.8174 26.2125 29.4963 28.7086 32.4424 28.7086C35.3955 28.7086 38.0674 26.2195 38.0674 22.0852C38.0674 17.9508 35.3955 15.4688 32.4424 15.4688Z"
        fill="#422B0D"
      />
      <path
        d="M27.9352 7.47422C27.6188 6.9539 27.5766 6.30703 27.8227 5.75156C28.0899 5.19609 28.6173 4.81641 29.229 4.73906C33.061 4.14141 36.9704 5.04844 40.1485 7.26328C41.3368 8.0789 42.3915 9.06328 43.2845 10.1953C43.3407 10.2656 43.3899 10.343 43.4391 10.4273C43.9173 11.1727 43.7555 12.157 43.0665 12.7125C42.7083 13.0091 42.2553 13.1666 41.7904 13.1563C41.3255 13.1459 40.8799 12.9684 40.5352 12.6562L40.8305 12.1781L40.472 12.6C37.3946 10.0257 33.5416 8.55977 29.5313 8.4375C28.8634 8.4375 28.2446 8.08594 27.9071 7.50937L27.9352 7.47422Z"
        fill="#422B0D"
      />
      <path
        d="M65.3913 20.0531C65.6482 19.9098 65.8675 19.7076 66.0313 19.4632C66.195 19.2187 66.2986 18.939 66.3335 18.6469C66.3556 18.3467 66.3029 18.0457 66.1802 17.7709C66.0575 17.496 65.8686 17.2559 65.6303 17.0719C62.5858 14.6461 58.7186 13.4859 54.8444 13.8516C53.41 13.9711 52.0038 14.3086 50.6679 14.85L50.4147 14.9695C49.6343 15.3844 49.2897 16.3195 49.6132 17.1422C49.7781 17.5736 50.0923 17.9315 50.4988 18.1509C50.9052 18.3703 51.3769 18.4365 51.828 18.3375V17.775L51.9264 18.3234C55.8639 17.5922 59.935 18.2039 63.4858 20.0602C64.0694 20.3906 64.7796 20.3906 65.3632 20.0602L65.3913 20.0531Z"
        fill="#422B0D"
      />
      <path
        d="M32.3161 17.6836C31.5919 17.1773 30.502 17.3391 29.7989 18.3516C29.0958 19.3711 29.3278 20.4398 30.052 20.9461C30.7762 21.4523 31.8661 21.2906 32.5692 20.2781C33.2723 19.2586 33.0403 18.1898 32.3161 17.6836Z"
        fill="#896024"
      />
      <path
        d="M56.651 23.7023C55.9268 23.1961 54.837 23.3578 54.1339 24.3703C53.4307 25.3898 53.6628 26.4586 54.387 26.9648C55.1112 27.4711 56.201 27.3094 56.9042 26.2969C57.6143 25.2773 57.3823 24.2086 56.651 23.7023Z"
        fill="#896024"
      />
      <defs>
        <radialGradient
          id="paint0_radial_148_43642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.7187 44.2266) scale(40.05)"
        >
          <stop offset="0.5" stopColor="#FDE030" />
          <stop offset="0.92" stopColor="#F7C02B" />
          <stop offset="1" stopColor="#F4A223" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_148_43642"
          x1="44.7187"
          y1="83.5312"
          x2="44.7187"
          y2="4.92188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4A223" />
          <stop offset="0.08" stopColor="#F7C02B" />
          <stop offset="0.54" stopColor="#FDE030" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_148_43642"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.1444 58.721) scale(41.7881 39.2808)"
        >
          <stop offset="0.27" stopColor="#FFF176" />
          <stop offset="1" stopColor="#FFC400" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default RethinkIcon;
