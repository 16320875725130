import React from "react";
import Container from "components/Container";
import notFoundGif from "assets/Images/404.gif";
import {
  StyledArticle,
  StyledDiv,
  StyledImg,
  StyledParagraph,
  StyledText,
} from "./styles";

function NotFoundPage() {
  return (
    <Container>
      <StyledArticle>
        <StyledImg src={notFoundGif} />
        <StyledDiv>
          <StyledText>
            4<span>0</span>4
          </StyledText>
          <StyledParagraph>Ups... coś poszło nie tak.</StyledParagraph>
        </StyledDiv>
      </StyledArticle>
    </Container>
  );
}

export default NotFoundPage;
