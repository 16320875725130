import styled, { DefaultTheme } from "styled-components";
import { Link } from "react-router-dom";
import Modal from "styled-react-modal";
import Button from "components/Button";

export const StyledModal = Modal.styled`
  padding: 74px 0 74px 0;
  min-height: 446px;
  width: 620px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 14px 25px ${({ theme }: { theme: DefaultTheme }) =>
    theme.colors.darkBlueModal};
  overflow: auto;
  outline: none;
  z-index: 5;
`;

export const StyledModalOverlay = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.darkBlueModal};
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledHeader = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.055em;
`;

export const StyledTextContent = styled(StyledIconWrapper)`
  margin: 37px 0 0 0;
  padding: 0 94px 20px 94px;
  width: 100%;
  flex-direction: column;
`;

export const StyledParagraph = styled.p`
  margin: 18px 0 0 0;
  padding: 0;
  text-align: center;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.gray_400};
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 37px 0 0 0;
`;

export const StyledButton = styled(Button)`
  margin: 0 21px;
  padding: 7.5px 0;
  width: 190px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
