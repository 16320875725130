import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ModalIcon from "components/Modal/ModalIcons";
import TickIcon from "components/Icons/TickIcon";
import ModalTextMessage from "components/Modal/ModalTextMessage";
import ModalButtons from "components/Modal/ModalButtons";
import { StyledButton } from "components/Modal/styles";
import { CompletedProps } from "./types";

const Completed: FC<CompletedProps> = ({ onAddNew }) => {
  const navigate = useNavigate();

  const handleEnd = () => {
    navigate("/");
  };
  return (
    <div>
      <ModalIcon>
        <TickIcon />
      </ModalIcon>
      <ModalTextMessage
        header="Gotowe!"
        info="Pytanie dodane. Dzięki za poświęcony czas!"
      />
      <ModalButtons>
        <StyledButton
          kind="secondary"
          label="Dodaj kolejne"
          onClick={onAddNew}
        />
        <StyledButton kind="primary" label="Koniec" onClick={handleEnd} />
      </ModalButtons>
    </div>
  );
};
export default Completed;
