import styled from "styled-components";

export const StyledArticle = styled.article`
  margin: 80px 0;
  display: flex;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  width: 1061px;
  padding: 33px 109px 37px 109px;
  background: ${({ theme }) => theme.colors.blue_100};
  border-radius: 12px;
`;
export const StyledHeader = styled.h2`
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.055em;
`;

export const StyledParagraph = styled.p`
  margin: 0 0 43px 0;
  color: ${({ theme }) => theme.colors.gray_400};
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.055em;
`;
export const StyledProgressBarUl = styled.ul`
  max-width: 2000px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  list-style: none;
`;
