import styled from "styled-components";

export const StyledHeaderTag = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledHeader = styled.h1`
  margin: 54px 0 23px 0;
  font-size: 57px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.055em;
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.blue_400};
`;

export const StyledParagraph = styled.p`
  margin: 0 0 33px 0;
  max-width: 463px;
  text-align: center;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.gray_400};
`;
