import styled, { css } from "styled-components";
import { Mode } from "./types";

export const StyledProgressBarLi = styled.li<{
  mode: Mode;
}>`
  min-width: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.blue_200};
    width: 200px;
    max-width: 700px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -200px;
    z-index: 1;
    transform: translateY(-50%);
  }
  &:first-child:before {
    content: none;
  }
`;

export const StyledProgressBarBtn = styled.button<{
  mode: Mode;
}>`
  ${(props) => {
    switch (props.mode) {
      case "previous":
        return css`
          background: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.blue_400};
        `;
      case "active":
        return css`
          background: ${({ theme }) => theme.colors.blue_400};
          color: ${({ theme }) => theme.colors.white};
        `;
      case "next":
        return css`
          background: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.blue_400};
        `;
    }
  }}
  width: 100%;
  height: 100%;
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.055em;
  border: 2px solid ${({ theme }) => theme.colors.blue_400};
  border-radius: 20px;
  cursor: pointer;
  z-index: 2;
`;
