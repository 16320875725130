import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const Check: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1.90997L5.65685 9.06682L0 3.40997L2 1.40997L5.74685 5.14685L11.09 2.38419e-06L13 1.90997Z"
      fill="#1D74F5"
    />
  </svg>
);

export default Check;
