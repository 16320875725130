import styled from "styled-components";

export const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-size: 261px;
  font-style: normal;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
  span {
    color: ${({ theme }) => theme.colors.blue_400};
  }
`;

export const StyledImg = styled.img`
  width: 262px;
`;

export const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledParagraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray_400};
`;

export const StyledDiv = styled.div`
  text-align: center;
  width: 760px;
  height: 370px;
  background-color: ${({ theme }) => theme.colors.blue_100};
  border-radius: 12px;
`;
