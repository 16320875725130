import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import CartDetailsProvider from "components/CartContext";
import { StyledModalOverlay } from "components/Modal/styles";
import QuestionPage from "pages/QuestionPage";
import CartPage from "pages/CartPage";
import AddNewQuestion from "pages/AddNewQuestion";
import Layout from "pages/Layout";
import { GlobalStyles } from "theme/GlobalStyles";
import NotFoundPage from "pages/NotFoundPage";
import theme from "./theme";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={StyledModalOverlay}>
        <QueryClientProvider client={queryClient}>
          <CartDetailsProvider>
            <ReactQueryDevtools />
            <GlobalStyles />
            <Router>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<QuestionPage />} />
                  <Route path="/questions-cart" element={<CartPage />} />
                  <Route
                    path="/add-new-question"
                    element={<AddNewQuestion />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </Router>
          </CartDetailsProvider>
        </QueryClientProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
