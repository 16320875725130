import styled from "styled-components";
import DownArrowIcon from "components/Icons/DownArrow";

export const StyledDiv = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
`;

export const StyledIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.gray_400};
`;

export const StyledParagraph = styled.p`
  margin: 0 0 1px 12px;
  font-weight: 600;
  letter-spacing: 0.055em;
`;

export const StyledLabel = styled.label`
  width: 348px;
  position: relative;
`;

export const StyledDownArrowIcon = styled(DownArrowIcon)<{ isOpen: boolean }>`
  margin: 0 12px 0 0;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  transition: 0.25s;
`;

export const StyledSelect = styled.div`
  width: 348px;
  height: 36px;
  & .custom__control {
    min-height: 36px;
    height: 36px;
    border: 1px solid ${({ theme }) => theme.colors.gray_400};
    border-radius: 14px;
    box-shadow: none;
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray_400};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue_400};
    }
  }
  & .custom__indicator-separator {
    position: relative;
    top: -1px;
    margin: 0 10px 0 0;
    height: 36px;
    background: ${({ theme }) => theme.colors.gray_400};
  }
  & .custom__control--menu-is-open {
    border-radius: 14px 14px 0 0;
    padding: 0;
  }
  & .custom__placeholder {
    color: ${({ theme }) => theme.colors.gray_400};
  }
  & .custom__value-container {
    padding: 0 0 0 10px;
  }
  & .custom__menu {
    margin: 0;
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray_400};
    border-top: none;
    border-radius: 0 0 14px 14px;
    box-shadow: none;
    overflow: hidden;
  }
  & .custom__menu-list {
    padding: 0;
  }
  & .custom__option {
    background-color: transparent;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue_200};
      color: ${({ theme }) => theme.colors.blue_400};
    }
  }
  & .custom__option--is-selected {
    height: 36px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue_400};
  }
`;
