import styled from "styled-components";

export const StyledArticle = styled.article`
  margin: 0 0 79px 0;
  display: flex;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  width: 1061px;
  padding: 34px 46px 26px;
  background: ${({ theme }) => theme.colors.green_100};
  border: none;
  border-radius: 12px;
`;
export const StyledTechnologyList = styled.ul`
  border: 0;
  color: ${({ theme }) => theme.colors.green_400};
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
`;
export const StyledHeader = styled.h2`
  margin: 0 0 30px 0;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.055em;
`;
