import { createClient } from "@supabase/supabase-js";
import data from "data";
import { Level, Question } from "types";
import { DBQuestion } from "./types";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

if (!API_URL) {
  throw new Error("Missing REACT_APP_API_URL");
}

if (!API_KEY) {
  throw new Error("Missing REACT_APP_API_KEY");
}

const supaBaseClient = createClient(API_URL, API_KEY);

const mapDBQuestionToQuestion = (dbQuestion: DBQuestion): Question => {
  const questionLevels: Level[] = [];
  const questionId = dbQuestion.id.toString();

  dbQuestion.levels.forEach((dbLevel) => {
    const level = data.experienceLevels.find(
      (dataLevel) => dataLevel.id === dbLevel
    );
    if (level) {
      questionLevels.push(level);
    }
  });

  const questionTechnology = data.technologies.find(
    (dataTech) => dataTech.id === dbQuestion.technology
  );

  const question = {
    id: questionId,
    title: dbQuestion.title,
    levels: questionLevels,
    answer: dbQuestion.answer,
    category: dbQuestion.category,
    technology: questionTechnology,
  };
  return question;
};

export const getQuestions = async (): Promise<Question[]> => {
  const { data } = await supaBaseClient
    .from<DBQuestion>("question")
    .select("id, category, title, answer, technology, levels");
  const questions: Question[] = (data || []).map((item) =>
    mapDBQuestionToQuestion(item)
  );

  return questions;
};
