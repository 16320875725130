import styled from "styled-components";

export const StyledThumb = styled.div`
  height: 45px;
  width: 20px;
  background-color: ${({ theme }) => theme.colors.blue_400};
  border-radius: 24px;
  cursor: grab;
  transform: translateY(-50%) translateY(2px);
  :focus-visible {
    outline: none;
  }
`;
