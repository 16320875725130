import React, { FC } from "react";
import ProgressBarButton from "components/ProgressBarButton";
import { ProgressBarProps } from "./types";
import {
  StyledArticle,
  StyledDiv,
  StyledHeader,
  StyledParagraph,
  StyledProgressBarUl,
} from "./styles";

function getButtonState(step: number, id: number) {
  if (step === id) {
    return "active";
  } else if (step > id) {
    return "previous";
  } else {
    return "next";
  }
}
const ProgressBar: FC<ProgressBarProps> = ({ step, onClick }) => {
  const firstStep = getButtonState(step, 0);
  const secondStep = getButtonState(step, 1);
  const thirdStep = getButtonState(step, 2);
  return (
    <StyledArticle>
      <StyledDiv>
        <StyledHeader>Rodzaj pytań</StyledHeader>
        <StyledParagraph>
          Wybierz grupę pytań, która Cię interesuje.
        </StyledParagraph>
        <StyledProgressBarUl>
          <ProgressBarButton
            id={0}
            label="Pytania ogólne"
            mode={firstStep}
            onClick={onClick}
          />
          <ProgressBarButton
            id={1}
            label="Technologie"
            mode={secondStep}
            onClick={onClick}
          />
          <ProgressBarButton
            id={2}
            label="Dobre praktyki"
            mode={thirdStep}
            onClick={onClick}
          />
        </StyledProgressBarUl>
      </StyledDiv>
    </StyledArticle>
  );
};

export default ProgressBar;
