import React, { FC } from "react";
import { ButtonState } from "components/TechnologyListItem/types";
import data from "data";
import TechnologyListItem from "../TechnologyListItem";
import { Technology } from "../../types";
import { TechnologyListProps } from "./types";
import {
  StyledArticle,
  StyledDiv,
  StyledHeader,
  StyledTechnologyList,
} from "./styles";

const TechnologyList: FC<TechnologyListProps> = ({
  selectedTechnologies,
  onSelectedTechnology,
}) => {
  const toggleTechnology: ButtonState = (id) => {
    if (selectedTechnologies.indexOf(id) >= 0) {
      const userLeftTechnologies: Technology["id"][] =
        selectedTechnologies.filter((selected) => selected !== id);
      onSelectedTechnology(userLeftTechnologies);
    } else {
      const userSelectedTechnologies: Technology["id"][] = [
        ...selectedTechnologies,
        id,
      ];
      onSelectedTechnology(userSelectedTechnologies);
    }
  };

  return (
    <StyledArticle>
      <StyledDiv>
        <StyledHeader>Technologie</StyledHeader>
        <StyledTechnologyList>
          {data.technologies.map((technology: Technology) => (
            <TechnologyListItem
              key={technology.id}
              technology={technology}
              isChecked={selectedTechnologies.indexOf(technology.id) >= 0}
              onClick={toggleTechnology}
            />
          ))}
        </StyledTechnologyList>
      </StyledDiv>
    </StyledArticle>
  );
};
export default TechnologyList;
