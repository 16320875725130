import styled from "styled-components";

export const StyledLegend = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 975px;
  display: flex;
  letter-spacing: 0.055em;
  white-space: nowrap;
  padding: 0 12px;
`;

export const StyledStep = styled.h3<{ isActive: boolean }>`
  box-sizing: border-box;
  flex: 1;
  display: inline-block;
  position: relative;
  color: ${({ isActive }) =>
    isActive
      ? ({ theme }) => theme.colors.blue_400
      : ({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  font-size: 20px;
  font-style: normal;
  text-align: center;
`;
