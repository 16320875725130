import React, { FC } from "react";
import { StyledSlider, StyledTitle, StyledDiv } from "./styles";
import { SliderProps } from "./types";
import Mark from "./Mark";
import Thumb from "./Thumb";
import Track from "./Track";
import Legend from "./Legend";

const Slider: FC<SliderProps> = ({ value, onChange }) => {
  return (
    <StyledDiv>
      <StyledTitle>wybierz poziom</StyledTitle>
      <StyledSlider
        marks
        min={0}
        max={4}
        onChange={onChange}
        value={value}
        renderThumb={Thumb}
        renderTrack={Track}
        renderMark={(props) => (
          <Mark {...props} value={value} markIndex={props.key} />
        )}
      ></StyledSlider>
      <Legend value={value} />
    </StyledDiv>
  );
};

export default Slider;
