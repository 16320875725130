import { StyleSheet, Font } from "@react-pdf/renderer";
import theme from "theme";
import robotoRegular from "assets/fonts/Roboto_400.ttf";
import robotoItalic from "assets/fonts/Roboto_italic.ttf";
import robotoBold from "assets/fonts/Roboto_700.ttf";
import robotoExtraBold from "assets/fonts/Roboto_900.ttf";
import sourceCodePro from "assets/fonts/source_code_pro.ttf";
import sourceCodeProItalic from "assets/fonts/source_code_pro_italic.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: robotoRegular,
      fontWeight: "normal",
      fontStyle: "normal",
    },
    { src: robotoItalic, fontWeight: "normal", fontStyle: "italic" },
    { src: robotoBold, fontWeight: "semibold", fontStyle: "normal" },
    { src: robotoExtraBold, fontWeight: "ultrabold", fontStyle: "normal" },
  ],
});

Font.register({
  family: "SourceCodePro",
  fonts: [
    {
      src: sourceCodePro,
      fontWeight: "normal",
      fontStyle: "normal",
    },
    { src: sourceCodeProItalic, fontWeight: "normal", fontStyle: "italic" },
  ],
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  boldText: {
    fontSize: "10pt",
    fontWeight: "semibold",
  },
  italicText: {
    fontSize: "10pt",
    fontStyle: "italic",
  },
  linethroughText: {
    fontSize: "10pt",
    textDecoration: "line-through",
  },
  quoteText: {
    color: theme.colors.gray_400,
    fontSize: "10pt",
    fontStyle: "italic",
  },
  listItem: {
    fontSize: "10pt",
  },
  imageElement: {
    width: "70mm",
    height: "50mm",
  },
  preElement: {
    fontFamily: "SourceCodePro",
    color: theme.colors.gray_600,
  },
  linkElement: {
    color: theme.colors.blue_400,
    textDecoration: "underline",
  },
});
