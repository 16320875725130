import React, { FC } from "react";
import {
  StyledCategoryIcon,
  StyledContainer,
  StyledLabel,
  StyledTechnologyTag,
} from "./styles";
import { TechnologyTagProps } from "./types";

const TechnologyTag: FC<TechnologyTagProps> = ({ technology }) => {
  return (
    <StyledTechnologyTag onClick={(e) => e.stopPropagation()}>
      <StyledContainer>
        <StyledCategoryIcon />
      </StyledContainer>
      <StyledLabel>{technology.label}</StyledLabel>
    </StyledTechnologyTag>
  );
};

export default TechnologyTag;
