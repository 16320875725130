import React, { FC, useState, useEffect } from "react";
import ModalError from "components/Modal/ModalError";
import {
  AcceptanceModalStep,
  ModalAcceptanceProps,
} from "components/ModalAcceptance/types";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import Completed from "./Completed";
import Confirm from "./Confirm";

const randomErrorTrigger = Math.floor(Math.random() * 2);

const ModalAcceptance: FC<ModalAcceptanceProps> = ({
  onToggle,
  onCancel,
  isOpen,
}) => {
  const [step, setStep] = useState<AcceptanceModalStep>("confirm");

  useEffect(() => {
    if (step === "processing") {
      setTimeout(() => {
        randomErrorTrigger === 1 ? setStep("error") : setStep("completed");
      }, 1500);
      return;
    }
  }, [step]);

  const handleConfirm = () => {
    setStep("processing");
  };

  const onAddNew = () => {
    setStep("confirm");
    onToggle();
  };

  const handleToggle = () => {
    if (step !== "processing") {
      onToggle();
    }
    if (step === "completed" || step === "error") {
      setStep("confirm");
    }
  };
  return (
    <Modal isOpen={isOpen} toggleModal={handleToggle}>
      {step === "confirm" && (
        <Confirm onConfirm={handleConfirm} onCancel={onCancel} />
      )}
      {step === "processing" && <Spinner />}
      {step === "error" && <ModalError onOk={handleToggle} />}
      {step === "completed" && (
        <Completed onAddNew={onAddNew} onFinish={handleToggle} />
      )}
    </Modal>
  );
};
export default ModalAcceptance;
