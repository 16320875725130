import React, { FC } from "react";
import ModalIcon from "components/Modal/ModalIcons";
import ErrorIcon from "components/Icons/ErrorIcon";
import ModalTextMessage from "components/Modal/ModalTextMessage";
import ModalButtons from "components/Modal/ModalButtons";
import { StyledButton } from "components/Modal/styles";
import { ModalErrorProps } from "./types";

const ModalError: FC<ModalErrorProps> = ({ onOk }) => {
  return (
    <div>
      <ModalIcon>
        <ErrorIcon />
      </ModalIcon>
      <ModalTextMessage header="Błąd" info="Wystąpił nieoczekiwany błąd." />
      <ModalButtons>
        <StyledButton kind="warning" label="Ok" onClick={onOk} />
      </ModalButtons>
    </div>
  );
};
export default ModalError;
