import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const RightArrowIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.99933L1.90438 0L0 1.88204L6.19529 8.00466L0 14.118L1.90438 16L10 7.99933Z"
      fill="#1D74F5"
    />
  </svg>
);

export default RightArrowIcon;
