import React, { FC } from "react";
import { ProgressBarProps } from "components/ProgressBar/types";
import NavigationButtonPrev from "components/NavigationButtons/NavigationButtonPrev";
import NavigationButtonNext from "components/NavigationButtons/NavigationButtonNext";
import { StyledDiv, StyledLink } from "./styles";

const navBtnLabels: string[] = [
  "Pytania ogólne",
  "Technologie",
  "Dobre praktyki",
  "Twoja lista",
];

const NavigationButtons: FC<ProgressBarProps> = ({ step, onClick }) => {
  return (
    <StyledDiv step={step}>
      {step >= 1 && (
        <NavigationButtonPrev
          step={step}
          label={navBtnLabels[step - 1]}
          onClick={onClick}
        />
      )}
      {step <= 1 && (
        <NavigationButtonNext
          step={step}
          label={navBtnLabels[step + 1]}
          onClick={onClick}
        />
      )}
      {step === 2 && (
        <StyledLink to="/questions-cart">
          <NavigationButtonNext
            step={step}
            label={navBtnLabels[step + 1]}
            onClick={onClick}
          />
        </StyledLink>
      )}
    </StyledDiv>
  );
};

export default NavigationButtons;
