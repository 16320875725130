import styled, { css } from "styled-components";
import SortingArrow from "components/Icons/SortingArrow";
import Delete from "components/Icons/Delete";

export const StyledQuestionList = styled.div`
  background-color: white;
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.055em;
  display: block;
`;

export const StyledQuestionHeader = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray_200};
  margin: 0px 20px;
  padding: 0px 18px;
  height: 60px;
  display: flex;
  align-items: center;
`;

const headerStyle = css`
  font-size: 12px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.gray_700};
  cursor: pointer;
`;

export const StyledCheckAll = styled.div`
  ${headerStyle}
  margin-right: 40px;
`;

export const StyledSorting = styled.div`
  ${headerStyle}
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledSortingArrows = styled(SortingArrow)<{ isSorted: boolean }>`
  transform: ${(props) => (props.isSorted ? "none" : "rotate(-180deg)")};
  margin-left: 6px;
`;

export const StyledDeleteIcon = styled(Delete)`
  margin-right: 6px;
`;

export const StyledDeleteQuestion = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red_400};
  cursor: pointer;
`;
