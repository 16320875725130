import { StyleSheet } from "@react-pdf/renderer";
import theme from "theme";

export const getTagColor = (color: string) =>
  StyleSheet.create({
    tagColor: {
      backgroundColor: color,
    },
  });

export const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    paddingBottom: "36pt",
  },
  header: {
    margin: "6pt 27pt",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  logoText: {
    margin: "15pt 2pt",
    fontSize: "17pt",
    fontWeight: "ultrabold",
    letterSpacing: "0.8pt",
  },
  logoTextBlue: {
    color: theme.colors.blue_400,
  },
  logoTextSmall: {
    margin: "0 2pt 18pt 2pt",
    fontSize: "5pt",
    fontWeight: "normal",
  },
  categoryWrapper: {
    marginTop: "10pt",
    padding: "0 10%",
    width: "100%",
  },
  categoryTitleWrapper: {
    marginBottom: "8pt",
    padding: "12pt 0pt",
    width: "100%",
    backgroundColor: theme.colors.blue_100,
    borderRadius: "12pt",
    textAlign: "center",
  },
  categoryTitle: {
    fontSize: "12pt",
    fontWeight: "semibold",
    letterSpacing: "0.8pt",
    color: theme.colors.blue_400,
  },
  mainQuestionContainer: {
    marginBottom: "16pt",
    padding: "8pt 0",
    flexDirection: "column",
    borderBottomWidth: "1pt",
    borderBottomStyle: "solid",
    borderBottomColor: theme.colors.gray_400,
  },
  questionWrapper: {
    paddingLeft: "10pt",
    paddingBottom: "5pt",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionTextWrapper: {
    alignItems: "flex-start",
  },
  questionText: {
    maxWidth: "80%",
    fontSize: "10pt",
    letterSpacing: "0.8pt",
  },
  tagContainer: {
    width: "50pt",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  tag: {
    margin: "1pt 0pt",
    padding: "1pt 4pt",
    width: "60pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "8pt",
    fontWeight: "bold",
    letterSpacing: "0.8pt",
    color: theme.colors.white,
    textAlign: "center",
    borderRadius: "10pt",
  },
  techTag: {
    width: "60pt",
    margin: "1pt 0",
    padding: "0 2pt",
    display: "flex",
    fontSize: "8pt",
    textAlign: "center",
    letterSpacing: "0.8pt",
    color: theme.colors.green_400,
    borderWidth: "1pt",
    borderStyle: "solid",
    borderColor: theme.colors.green_400,
    borderRadius: "10pt",
  },
  mainAnswerContainer: {
    margin: "8pt 0pt 8pt 0pt",
    borderTopWidth: "1pt",
    borderTopStyle: "solid",
    borderTopColor: theme.colors.gray_200,
  },
  blueAnswerTextWrapper: {
    marginLeft: "12pt",
    padding: "5pt 0pt 3pt 0",
    width: "60pt",
  },
  blueAnswerText: {
    fontSize: "10pt",
    fontWeight: "semibold",
    letterSpacing: "0.8pt",
    color: theme.colors.blue_400,
  },
  answerWrapper: {
    marginLeft: "15pt",
  },
  answerText: {
    padding: "10pt 0 10pt 10pt",
    maxWidth: "90%",
    fontSize: "10pt",
    letterSpacing: "0.8pt",
    borderLeftWidth: "1pt",
    borderLeftStyle: "solid",
    borderLeftColor: theme.colors.blue_400,
  },
});
