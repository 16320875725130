import React, { FC } from "react";
import { StyledButton, StyledParagraph, StyledDiv } from "./styles";
import { QuestionGroupProps } from "./types";

const QuestionsGroupButtons: FC<QuestionGroupProps> = ({
  selectedCategory,
  onClick,
}) => {
  return (
    <StyledDiv>
      <StyledParagraph>Grupa pytań</StyledParagraph>
      <StyledButton
        kind={selectedCategory === "generic" ? "primary" : "alternative"}
        label="Pytania ogólne"
        onClick={() => onClick("generic")}
      />
      <StyledButton
        kind={selectedCategory === "technology" ? "primary" : "alternative"}
        label="Technologie"
        onClick={() => onClick("technology")}
      />
      <StyledButton
        kind={selectedCategory === "good-practices" ? "primary" : "alternative"}
        label="Dobre praktyki"
        onClick={() => onClick("good-practices")}
      />
    </StyledDiv>
  );
};

export default QuestionsGroupButtons;
