import styled from "styled-components";
import ReactSlider from "react-slider";

export const StyledDiv = styled.div`
  margin: 79px 0 78px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSlider = styled(ReactSlider)`
  margin: 0 0 11px 0;
  width: 100%;
  max-width: 780px;
  height: 25px;
  box-sizing: border-box;
  position: relative;
`;

export const StyledTitle = styled.p`
  margin-bottom: 33px;
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.black};
  opacity: 50%;
`;
