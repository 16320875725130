import styled from "styled-components";

export const StyledEditor = styled.div`
  width: 600px;
  flex-grow: 1;

  &:focus-visible {
    outline: none;
  }
  .rc-md-editor {
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray_400};
    border-radius: 14px;
    overflow: hidden;
  }
  .rc-md-editor .rc-md-navigation {
    background-color: white;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_400};
  }
  .rc-md-editor .editor-container textarea.input {
    height: 100%;
    overflow-y: scroll;
    width: calc(100% + 20px);
  }
`;

export const StyledRemark = styled.div`
  width: 600px;
  height: 100%;
  border-radius: 14px;
  background-color: white;
  padding: 16px;
  overflow-y: scroll;
  flex-grow: 1;
  white-space: pre-line;
  ul {
    list-style: inside;
  }
  ol {
    list-style: auto;
    list-style-position: inside;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledParagraph = styled.p`
  font-weight: 600;
  letter-spacing: 0.055em;
  padding: 20px 0 6px 10px;
`;

export const StyledDisplay = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
