import React, { FC } from "react";
import { IconProps } from "../types";

const ErrorIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 45C1 20.6995 20.6995 1 45 1C69.3005 1 89 20.6995 89 45V45.0022C88.9733 69.2905 69.2905 88.9733 45.0022 89H45C20.6995 89 1 69.3005 1 45ZM44.9664 13.4C27.5204 13.4 13.3751 27.5382 13.3664 44.9842C13.3577 62.4302 27.4888 76.5826 44.9348 76.6H44.998C62.444 76.5826 76.5751 62.4302 76.5664 44.9842C76.5577 27.5382 62.4124 13.4 44.9664 13.4Z"
        fill="#DD3C58"
      />
      <rect
        x="32.2725"
        y="23.7868"
        width="48"
        height="12"
        transform="rotate(45 32.2725 23.7868)"
        fill="#FF687D"
      />
      <rect
        x="23.7871"
        y="57.7279"
        width="48"
        height="12"
        transform="rotate(-45 23.7871 57.7279)"
        fill="#DD3C58"
      />
    </svg>
  );
};

export default ErrorIcon;
