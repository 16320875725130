import styled from "styled-components";

export const StyledTag = styled.button<{ color: string }>`
  height: 22px;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  margin-right: 18px;
  padding: 0 10px;
  border: none;
  background-color: ${({ color }) => color};
`;
