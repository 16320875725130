import React, { FC } from "react";
import { IconProps } from "../types";

const BoyIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.4884 67.4297C69.6376 57.2414 48.3962 56.5031 45.3517 56.4469V56.4398H44.972V56.4469C41.9275 56.5031 20.6861 57.2414 10.8353 67.4297C3.65636 74.8547 5.20324 82.0547 6.69386 84.1711C9.51339 88.1648 17.065 89.9719 17.065 89.9719H73.2446C73.2446 89.9719 80.7962 88.1648 83.6157 84.1711C85.1134 82.0547 86.6673 74.8547 79.4884 67.4297Z"
        fill="#1D74F5"
      />
      <path
        opacity="0.2"
        d="M59.2525 69.743C53.9299 68.0273 46.6947 68.1117 45.0002 68.1539C43.3057 68.1047 36.0705 68.0203 30.7479 69.743C20.0674 73.1953 18.8018 77.5617 18.8018 77.5617L35.4377 90H54.5697L71.1986 77.5617C71.2057 77.5617 69.94 73.1953 59.2525 69.743Z"
        fill="#BBD6FF"
      />
      <path
        d="M44.9717 63.9211H38.8193V70.418C38.8193 73.357 41.3225 75.7406 44.4092 75.7406H45.5271C48.6139 75.7406 51.117 73.357 51.117 70.418V63.9211H44.9717Z"
        fill="#DBA689"
      />
      <path
        d="M66.699 47.7914C66.699 47.7914 70.6576 48.4805 70.6576 52.7414C70.6576 56.5031 67.7678 57.9938 64.8779 57.9938V47.7914H66.699Z"
        fill="#DBA689"
      />
      <path
        d="M23.4918 47.7914C23.4918 47.7914 19.5332 48.4805 19.5332 52.7414C19.5332 56.5031 22.423 57.9938 25.3129 57.9938V47.7914H23.4918Z"
        fill="#DBA689"
      />
      <path
        d="M45.0916 19.8492C28.0197 19.8492 23.5479 32.1187 23.5479 49.3594C23.5479 67.2328 35.9369 71.8594 45.0916 71.8594C54.1057 71.8594 66.6353 67.3664 66.6353 49.3594C66.6353 32.1258 62.1705 19.8492 45.0916 19.8492Z"
        fill="#FADCBC"
      />
      <path
        d="M38.0037 54.7594C38.0037 56.6789 36.717 58.2328 35.1279 58.2328C33.5389 58.2328 32.2451 56.6789 32.2451 54.7594C32.2451 52.8328 33.5389 51.2789 35.1279 51.2789C36.717 51.2789 38.0037 52.8328 38.0037 54.7594Z"
        fill="#262626"
      />
      <path
        d="M52.1855 54.7594C52.1855 56.6789 53.4723 58.2328 55.0684 58.2328C56.6574 58.2328 57.9512 56.6789 57.9512 54.7594C57.9512 52.8328 56.6574 51.2789 55.0684 51.2789C53.4723 51.2789 52.1855 52.8328 52.1855 54.7594Z"
        fill="#262626"
      />
      <path
        d="M48.1074 62.557C47.0456 62.8594 45.9488 63.007 45.098 63.007C44.2472 63.007 43.1503 62.8594 42.0886 62.557C41.6316 62.4305 41.4558 62.8594 41.6175 63.1406C41.955 63.7313 43.312 64.9266 45.098 64.9266C46.8839 64.9266 48.2409 63.7313 48.5784 63.1406C48.7402 62.8594 48.5574 62.4305 48.1074 62.557Z"
        fill="#DBA689"
      />
      <path
        d="M45.6121 17.4234C45.6121 17.4234 45.2816 17.4164 45.134 17.4164C44.9863 17.4164 44.6559 17.4234 44.6559 17.4234C26.234 17.782 19.3223 30.8953 19.3223 38.5805C19.3223 46.0477 20.6863 49.507 20.6863 49.507C20.6863 49.507 20.9816 49.2047 21.523 48.7828C22.0996 48.3328 22.7395 48.0797 22.7395 48.0797L23.6676 54.3164L29.8059 45.5555C29.841 45.4781 29.9254 45.4289 30.0168 45.4359L33.652 45.8367C37.3082 46.2445 40.9855 46.4414 44.6559 46.4484C44.6559 46.4484 44.9723 46.4555 45.134 46.4555C45.2957 46.4555 45.6121 46.4484 45.6121 46.4484C49.2824 46.4344 52.9598 46.2375 56.616 45.8367L60.2512 45.4359C60.3355 45.4289 60.4199 45.4711 60.4621 45.5555L66.6004 54.3164L67.5285 48.0797C67.5285 48.0797 68.1684 48.3328 68.7449 48.7828C69.2863 49.2047 69.5816 49.507 69.5816 49.507C69.5816 49.507 70.9457 46.0477 70.9457 38.5805C70.9387 30.8953 64.034 17.775 45.6121 17.4234Z"
        fill="#312D2D"
      />
      <path
        d="M36.9561 13.3523L35.0576 6.25781"
        stroke="#444444"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.5008 18.5766L21.3047 13.3875"
        stroke="#444444"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M63.499 18.5766L68.6951 13.3875"
        stroke="#444444"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M53.0439 13.3523L54.9424 6.25781"
        stroke="#444444"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.9451 82.5539L29.7209 82.807C30.5717 82.807 31.4154 82.9336 32.2311 83.1797C34.7623 83.9531 40.3451 85.9008 43.3756 86.6742C44.1209 86.864 44.6412 87.539 44.6412 88.3054C44.6412 88.753 44.4634 89.1822 44.147 89.4987C43.8305 89.8152 43.4013 89.993 42.9537 89.993H23.7373L25.9451 82.5539Z"
        fill="#FADCBC"
      />
      <path
        d="M64.0682 82.5539L60.2924 82.807C59.4416 82.807 58.5979 82.9336 57.7822 83.1797C55.251 83.9531 49.6682 85.9008 46.6377 86.6742C45.8924 86.864 45.3721 87.539 45.3721 88.3054C45.3721 88.753 45.5499 89.1822 45.8663 89.4987C46.1828 89.8152 46.612 89.993 47.0596 89.993H66.276L64.0682 82.5539Z"
        fill="#FADCBC"
      />
      <path
        d="M19.1527 74.6859L27.302 80.1422C27.8504 80.5078 28.0543 81.218 27.7871 81.8227L24.1098 90H17.0645L19.1527 74.6859Z"
        fill="#4EBE8C"
      />
      <path
        d="M70.8605 74.6859L62.7113 80.1422C62.1628 80.5078 61.9589 81.218 62.2261 81.8227L65.8964 90H73.2441L70.8605 74.6859Z"
        fill="#4EBE8C"
      />
    </svg>
  );
};

export default BoyIcon;
