import React, { FC } from "react";
import { IconProps } from "../types";

const DeleteAll: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.73242 8L0.616211 14.1162L2.38398 15.884L8.50019 9.76777L14.6164 15.884L16.3842 14.1162L10.268 8L16.384 1.88398L14.6162 0.116211L8.50019 6.23223L2.38417 0.116211L0.6164 1.88398L6.73242 8Z"
      fill="white"
    />
  </svg>
);

export default DeleteAll;
