import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const Component: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 7C9.29 7 7.5 8.79 7.5 11C7.5 13.21 9.29 15 11.5 15C13.71 15 15.5 13.21 15.5 11C15.5 8.79 13.71 7 11.5 7ZM20.44 10C19.98 5.83 16.67 2.52 12.5 2.06V0H10.5V2.06C6.33 2.52 3.02 5.83 2.56 10H0.5V12H2.56C3.02 16.17 6.33 19.48 10.5 19.94V22H12.5V19.94C16.67 19.48 19.98 16.17 20.44 12H22.5V10H20.44ZM11.5 18C7.63 18 4.5 14.87 4.5 11C4.5 7.13 7.63 4 11.5 4C15.37 4 18.5 7.13 18.5 11C18.5 14.87 15.37 18 11.5 18Z"
      fill="#4EBE8C"
    />
  </svg>
);

export { Component as CategoryIcon };
