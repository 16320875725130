import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.primaryFontFamily}
  }

  *, *:before, *:after {
    box-sizing: inherit;
    outline: none;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  div {
    box-sizing: border-box;
  }

  table {
    margin-top: 10px;
    border-collapse: collapse;
  }

  th {
    padding: 8px 16px;
    color:  ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue_400};
    &:first-child {
    border-radius: 8px 0 0 0;
  }
    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }
  
  tr {
    &:nth-child(even){
      background-color: ${({ theme }) => theme.colors.blue_100};
    }
  }

  td {
    padding: 8px 16px;  
  }

`;
