import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const LeftArrowIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-2.5435e-07 8.00066L8.09562 16L10 14.118L3.80471 7.99534L10 1.88204L8.09562 7.87188e-07L-2.5435e-07 8.00066Z"
      fill="white"
    />
  </svg>
);

export default LeftArrowIcon;
