import React, { createContext, FC, useState } from "react";
import { Question } from "types";
import { CartContextType } from "./types";

export const cartDetailsContext = createContext<CartContextType>({
  selectedQuestions: [],
  setSelectedQuestions: () => {},
});

const CartDetailsProvider: FC = ({ children }) => {
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  return (
    <cartDetailsContext.Provider
      value={{ selectedQuestions, setSelectedQuestions }}
    >
      {children}
    </cartDetailsContext.Provider>
  );
};

export default CartDetailsProvider;
