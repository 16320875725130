import React from "react";
import { Outlet } from "react-router";
import TopMenu from "components/TopMenu";

function Layout() {
  return (
    <>
      <TopMenu />
      <Outlet />
    </>
  );
}

export default Layout;
