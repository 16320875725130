import React from "react";
import { StyledMark } from "./styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Mark = (props: any) => {
  const isActive =
    props.markIndex >= props.value[0] && props.markIndex <= props.value[1];
  return <StyledMark {...props} isActive={isActive}></StyledMark>;
};

export default Mark;
