import React from "react";
import { useLocation } from "react-router";
import Logo from "components/Logo";
import CartListButton from "components/CartListButton";
import {
  StyledDiv,
  StyledLogo,
  StyledRightMenu,
  StyledTopMenu,
  StyledLink,
} from "./styles";

const TopMenu = () => {
  const errorLocation = useLocation().pathname.match("/404");
  return (
    <StyledTopMenu>
      <StyledDiv>
        <StyledLogo to={"/"}>
          What <span>Dev</span> Faq <span>by</span> <Logo />
        </StyledLogo>
        {!errorLocation && (
          <StyledRightMenu>
            <StyledLink to={"/questions-cart"}>
              <CartListButton />
            </StyledLink>
          </StyledRightMenu>
        )}
      </StyledDiv>
    </StyledTopMenu>
  );
};

export default TopMenu;
