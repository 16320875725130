import styled from "styled-components";
import { CategoryIcon } from "components/Icons/categoryIcon";

export const StyledTechnologyTag = styled.button`
  height: 22px;
  border: 2px solid ${({ theme }) => theme.colors.green_400};
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
`;

export const StyledContainer = styled.div`
  padding-top: 3px;
  text-align: left;
`;

export const StyledCategoryIcon = styled(CategoryIcon)`
  height: 13px;
`;

export const StyledLabel = styled.span`
  color: ${({ theme }) => theme.colors.green_400};
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  font-size: 12px;
  text-align: right;
`;
