import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const AddIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
      fill="black"
    />
    <rect x="11" y="8" width="2" height="8" fill="black" />
    <rect
      x="16"
      y="11"
      width="2"
      height="8"
      transform="rotate(90 16 11)"
      fill="black"
    />
  </svg>
);

export default AddIcon;
