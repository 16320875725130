import styled from "styled-components";

export const StyledMark = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ isActive }) =>
    isActive
      ? ({ theme }) => theme.colors.blue_400
      : ({ theme }) => theme.colors.black};
  border-radius: 50%;
  top: -6px;
`;
