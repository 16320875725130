import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import "react-markdown-editor-lite/lib/index.css";
import Editor, { Plugins } from "react-markdown-editor-lite";
import remarkGfm from "remark-gfm";
import remarkImages from "remark-images";
import remarkBreaks from "remark-breaks";
import {
  StyledContainer,
  StyledDisplay,
  StyledEditor,
  StyledParagraph,
  StyledRemark,
} from "./styles";

Editor.unuse(Plugins.FullScreen);
Editor.unuse(Plugins.ModeToggle);
Editor.unuse(Plugins.Logger);
Editor.unuse(Plugins.Clear);
Editor.unuse(Plugins.BlockWrap);
Editor.unuse(Plugins.Header);
Editor.unuse(Plugins.FontUnderline);
Editor.unuse(Plugins.BlockCodeBlock);

const ContentEditor = () => {
  const [markdownSource, setMarkdownSource] = useState(
    "To jest jakieś **ciekawe pytanie**"
  );
  const [markdownSourceAnswer, setMarkdownSourceAnswer] = useState(
    "Tutaj zamieść swoją odpowiedź"
  );
  return (
    <>
      <StyledContainer>
        <StyledDisplay>
          <StyledContainer>
            <StyledParagraph>Pytanie</StyledParagraph>
            <StyledEditor>
              <Editor
                style={{ minHeight: "130px" }}
                placeholder={markdownSource}
                onChange={({ html, text }, event) => setMarkdownSource(text)}
                renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
                view={{ menu: true, html: false, md: true }}
              />
            </StyledEditor>
          </StyledContainer>
          <StyledContainer>
            <StyledParagraph>Podgląd</StyledParagraph>
            <StyledRemark>
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkImages, remarkBreaks]}
              >
                {markdownSource}
              </ReactMarkdown>
            </StyledRemark>
          </StyledContainer>
        </StyledDisplay>
        <StyledDisplay>
          <StyledContainer>
            <StyledParagraph>Odpowiedź</StyledParagraph>
            <StyledEditor>
              <Editor
                style={{ minHeight: "256px" }}
                placeholder={markdownSourceAnswer}
                onChange={({ html, text }, event) =>
                  setMarkdownSourceAnswer(text)
                }
                renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
                view={{ menu: true, html: false, md: true }}
              />
            </StyledEditor>
          </StyledContainer>
          <StyledContainer>
            <StyledParagraph>Podgląd</StyledParagraph>
            <StyledRemark>
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkImages, remarkBreaks]}
              >
                {markdownSourceAnswer}
              </ReactMarkdown>
            </StyledRemark>
          </StyledContainer>
        </StyledDisplay>
      </StyledContainer>
    </>
  );
};

export default ContentEditor;
