import React, { FC } from "react";
import { IconProps } from "../types";

const TickIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_148_43750)">
        <rect
          x="21.6924"
          y="31.409"
          width="48.1236"
          height="19.7327"
          transform="rotate(45 21.6924 31.409)"
          fill="#1D74F5"
        />
        <rect
          x="88.7676"
          y="32.3905"
          width="66.4681"
          height="19.7327"
          transform="rotate(135 88.7676 32.3905)"
          fill="#BBD6FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_43750">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TickIcon;
