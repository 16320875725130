import React, { FC } from "react";
import LeftArrowIcon from "components/Icons/LeftArrow";
import ListIcon from "components/Icons/ListIcon";
import RightArrowIcon from "components/Icons/RightArrow";
import AddIcon from "components/Icons/Add";
import DeleteAll from "components/Icons/DeleteAll";
import { StyledButton } from "./styles";
import { ButtonProps, IconDict } from "./types";

const iconDict: IconDict = {
  leftArrow: <LeftArrowIcon />,
  rightArrow: <RightArrowIcon />,
  listIcon: <ListIcon />,
  addIcon: <AddIcon />,
  deleteIcon: <DeleteAll />,
};
const Button: FC<ButtonProps> = ({
  label,
  kind,
  disabled,
  leftIcon,
  rightIcon,
  className,
  onClick,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      className={className}
      kind={kind}
      disabled={disabled}
    >
      {leftIcon && iconDict[leftIcon]}
      <span>{label}</span>
      {rightIcon && iconDict[rightIcon]}
    </StyledButton>
  );
};

export default Button;
