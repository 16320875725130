import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ModalIcon from "components/Modal/ModalIcons";
import TickIcon from "components/Icons/TickIcon";
import ModalTextMessage from "components/Modal/ModalTextMessage";
import ModalButtons from "components/Modal/ModalButtons";
import { StyledButton } from "components/Modal/styles";
import { ReadyProps } from "./types";

const Ready: FC<ReadyProps> = () => {
  const navigate = useNavigate();

  const handleEnd = () => {
    navigate("/");
  };

  return (
    <div>
      <ModalIcon>
        <TickIcon />
      </ModalIcon>
      <ModalTextMessage header="Gotowe!" info="Zaczynasz z czystą listą." />
      <ModalButtons>
        <StyledButton kind="primary" label="Ok" onClick={handleEnd} />
      </ModalButtons>
    </div>
  );
};

export default Ready;
