import React, { FC } from "react";
import { StyledProgressBarLi, StyledProgressBarBtn } from "./styles";
import { ProgressBarButtonProps } from "./types";
const ProgressBarButton: FC<ProgressBarButtonProps> = ({
  label,
  onClick,
  id,
  mode,
}) => {
  return (
    <StyledProgressBarLi mode={mode}>
      <StyledProgressBarBtn mode={mode} onClick={() => onClick(id)}>
        {label}
      </StyledProgressBarBtn>
    </StyledProgressBarLi>
  );
};

export default ProgressBarButton;
