import React, { FC } from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import { Category, Level } from "../../../types";
import { LogoOnPDF } from "../LogoOnPDF";
import RemarkOnPDF from "../RemarkOnPDF";
import { getTagColor, styles } from "./styles";
import { QuestionListPDFProps } from "./types";

export const QuestionListPDF: FC<QuestionListPDFProps> = ({
  questions,
  renderWithAnswers,
}) => {
  const category: Category[] = ["generic", "technology", "good-practices"];
  const genericQuestions = questions.filter((q) => q.category === category[0]);
  const techQuestions = questions.filter((q) => q.category === category[1]);
  const goodPracticesQuestions = questions.filter(
    (question) => question.category === category[2]
  );

  const getTagStyles = (level: Level) => {
    const tagColor = getTagColor(level.color);
    return [styles.tag, tagColor.tagColor];
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.header}>
          <Text style={styles.logoText}>What</Text>
          <Text style={[styles.logoText, styles.logoTextBlue]}>Dev</Text>
          <Text style={styles.logoText}>Faq</Text>
          <Text style={[styles.logoText, styles.logoTextSmall]}>by</Text>
          <LogoOnPDF />
        </View>
        {genericQuestions.length > 0 && (
          <View style={styles.categoryWrapper}>
            <View wrap={false} style={styles.categoryTitleWrapper}>
              <Text style={styles.categoryTitle}>Pytania ogólne</Text>
            </View>
            {genericQuestions.map((question, index) => {
              return (
                <View style={styles.mainQuestionContainer} key={question.id}>
                  <View style={styles.questionWrapper}>
                    <View style={styles.questionTextWrapper}>
                      <Text style={styles.questionText}>
                        {index + 1 + ". "}
                        <RemarkOnPDF dataToRemark={question.title} />
                      </Text>
                    </View>
                    <View style={styles.tagContainer}>
                      {question.levels.map((level) => {
                        return (
                          <View key={level.id}>
                            <Text break style={getTagStyles(level)}>
                              {level.label}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  {renderWithAnswers && (
                    <View style={styles.mainAnswerContainer}>
                      <View style={styles.blueAnswerTextWrapper}>
                        <Text style={styles.blueAnswerText}>Odpowiedź:</Text>
                      </View>
                      <View style={styles.answerWrapper}>
                        <Text style={styles.answerText}>
                          <RemarkOnPDF dataToRemark={question.answer} />
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
        {techQuestions.length > 0 && (
          <View style={styles.categoryWrapper}>
            <View wrap={false} style={styles.categoryTitleWrapper}>
              <Text style={styles.categoryTitle}>Technologie</Text>
            </View>
            {techQuestions.map((question, index) => {
              return (
                <View key={question.id} style={styles.mainQuestionContainer}>
                  <View style={styles.questionWrapper}>
                    <View style={styles.questionTextWrapper}>
                      <Text style={styles.questionText}>
                        {index + 1 + ". "}
                        <RemarkOnPDF dataToRemark={question.title} />
                      </Text>
                    </View>
                    <View style={styles.tagContainer}>
                      {question.levels.map((level) => {
                        return (
                          <View key={level.id}>
                            <Text style={getTagStyles(level)}>
                              {level.label}
                            </Text>
                          </View>
                        );
                      })}
                      <View wrap={false} style={styles.techTag}>
                        <Text>{question.technology?.label}</Text>
                      </View>
                    </View>
                  </View>
                  {renderWithAnswers && (
                    <View style={styles.mainAnswerContainer}>
                      <View style={styles.blueAnswerTextWrapper}>
                        <Text style={styles.blueAnswerText}>Odpowiedź:</Text>
                      </View>
                      <View style={styles.answerWrapper}>
                        <Text style={styles.answerText}>
                          <RemarkOnPDF dataToRemark={question.answer} />
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
        {goodPracticesQuestions.length > 0 && (
          <View style={styles.categoryWrapper}>
            <View wrap={false} style={styles.categoryTitleWrapper}>
              <Text style={styles.categoryTitle}>Dobre praktyki</Text>
            </View>
            {goodPracticesQuestions.map((question, index) => {
              return (
                <View style={styles.mainQuestionContainer} key={question.id}>
                  <View key={question.id} style={styles.questionWrapper}>
                    <View style={styles.questionTextWrapper}>
                      <Text style={styles.questionText}>
                        {index + 1 + ". "}
                        <RemarkOnPDF dataToRemark={question.title} />
                      </Text>
                    </View>
                    <View wrap={false} style={styles.tagContainer}>
                      {question.levels.map((level) => {
                        return (
                          <View key={level.id}>
                            <Text style={getTagStyles(level)}>
                              {level.label}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  {renderWithAnswers && (
                    <View style={styles.mainAnswerContainer}>
                      <View style={styles.blueAnswerTextWrapper}>
                        <Text style={styles.blueAnswerText}>Odpowiedź:</Text>
                      </View>
                      <View style={styles.answerWrapper}>
                        <Text style={styles.answerText}>
                          <RemarkOnPDF dataToRemark={question.answer} />
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
      </Page>
    </Document>
  );
};
