import React, { useContext } from "react";
import CartPageHeader from "components/CartPageHeader";
import CartQuestionList from "components/CartQuestionList";
import CartPageBottom from "components/CartPageBottom";
import { cartDetailsContext } from "components/CartContext";

function CartPage() {
  const { selectedQuestions } = useContext(cartDetailsContext);

  return (
    <>
      <CartPageHeader selectedQuestions={selectedQuestions} />
      {selectedQuestions.length > 0 && (
        <>
          <CartQuestionList />
          <CartPageBottom />
        </>
      )}
    </>
  );
}

export default CartPage;
