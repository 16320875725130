import React, { FC } from "react";
import Button from "components/Button";
import { ProgressBarProps } from "components/ProgressBar/types";

const NavigationButtonPrev: FC<ProgressBarProps> = ({
  step,
  label,
  onClick,
}) => {
  return (
    <Button
      label={`${label}`}
      kind="secondary"
      leftIcon="leftArrow"
      onClick={() => onClick(step - 1)}
    ></Button>
  );
};
export default NavigationButtonPrev;
