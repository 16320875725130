import styled from "styled-components";

export const StyledListHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.blue_100};
  color: ${({ theme }) => theme.colors.blue_400};
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.055em;
  height: 81px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
`;

export const StyledSection = styled.section`
  display: block;
`;

export const StyledEditButtonDisplay = styled.div`
  display: flex;
  justify-content: right;
  margin: 30px 0;
`;
