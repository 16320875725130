import React, { FC } from "react";
import Button from "components/Button";
import { ProgressBarProps } from "components/ProgressBar/types";

const NavigationButtonNext: FC<ProgressBarProps> = ({
  step,
  label,
  onClick,
}) => {
  return (
    <Button
      label={`${label}`}
      kind="primary"
      rightIcon="rightArrow"
      onClick={() => onClick(step + 1)}
    ></Button>
  );
};

export default NavigationButtonNext;
