import React, { useState } from "react";
import ModalAcceptance from "components/ModalAcceptance";
import QuestionsGroupButtons from "components/QuestionsGroupButtons";
import Button from "components/Button";
import DropdownTechnologies from "components/DropdownTechnologies";
import { Category, Technology } from "types";
import ContentEditor from "components/ContentEditor";
import {
  StyledHeaderSection,
  StyledFormSection,
  StyledHeader,
  StyledFormDiv,
  StyledParagraph,
} from "./styles";

const AddNewQuestion = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>("generic");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTechnology, setSelectedTechnology] =
    useState<Technology | null>(null);

  const handleCancel = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <StyledHeaderSection>
        <StyledHeader>Dodaj pytanie do bazy</StyledHeader>
        <StyledParagraph>
          Wypełnij poniższy formularz, aby dodać pytanie.
        </StyledParagraph>
      </StyledHeaderSection>
      <StyledFormSection>
        <StyledFormDiv>
          <QuestionsGroupButtons
            selectedCategory={selectedCategory}
            onClick={setSelectedCategory}
          />
          {selectedCategory === "technology" && (
            <DropdownTechnologies
              onSelectedTechnology={setSelectedTechnology}
            />
          )}
          <ContentEditor />
          <Button label="Dodaj pytanie" kind={"primary"} />
        </StyledFormDiv>
      </StyledFormSection>
      <button onClick={toggleModal}>Click here to toogle Modal :)</button>
      <ModalAcceptance
        isOpen={isOpen}
        onToggle={toggleModal}
        onCancel={handleCancel}
      />
    </div>
  );
};
export default AddNewQuestion;
