import React, { FC } from "react";
import Select, { SingleValue } from "react-select";
import data from "data";
import {
  StyledDiv,
  StyledParagraph,
  StyledLabel,
  StyledDownArrowIcon,
  StyledSelect,
} from "./styles";
import { DropdownTechnologiesProps, Option } from "./types";

const options: Option[] = data.technologies.map((technology) => ({
  value: technology,
  label: technology.label,
}));

const DropdownTechnologies: FC<DropdownTechnologiesProps> = ({
  onSelectedTechnology,
}) => {
  const handleOnChange = (option: SingleValue<Option>) => {
    onSelectedTechnology(option?.value || null);
  };
  return (
    <StyledDiv>
      <StyledParagraph>Technologia</StyledParagraph>
      <StyledLabel>
        <StyledSelect>
          <Select
            onChange={handleOnChange}
            options={options}
            placeholder={"-"}
            classNamePrefix="custom"
            components={{
              DropdownIndicator: (props) => (
                <StyledDownArrowIcon isOpen={props.selectProps.menuIsOpen} />
              ),
            }}
          />
        </StyledSelect>
      </StyledLabel>
    </StyledDiv>
  );
};

export default DropdownTechnologies;
