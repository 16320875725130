import theme from "theme";
import { Level, Technology } from "./types";

const technologies: Technology[] = [
  { id: "js", label: "JavaScript" },
  { id: "nodejs", label: "NodeJS" },
  { id: "php", label: "PHP" },
  { id: "react", label: "React" },
  { id: "typescript", label: "Typescript" },
  { id: ".netcore", label: ".NET Core" },
  { id: "angularjs", label: "AngularJS" },
  { id: "android", label: "Android" },
  { id: "css", label: "CSS" },
  { id: "agilescrum", label: "Agile | Scrum" },
  { id: "designpatterns", label: "Design Patterns" },
];

export const experienceLevels: Level[] = [
  {
    id: "entry",
    label: "entry",
    color: theme.colors.blue_300,
  },
  {
    id: "junior",
    label: "junior",
    color: theme.colors.green_400,
  },
  {
    id: "middle",
    label: "middle",
    color: theme.colors.yellow,
  },
  {
    id: "senior",
    label: "senior",
    color: theme.colors.red_200,
  },
  {
    id: "expert",
    label: "expert",
    color: theme.colors.violet,
  },
];

const data = {
  technologies,
  experienceLevels,
};
export default data;
