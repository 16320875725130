import React, { useContext, useEffect, useState } from "react";
import { usePDF } from "@react-pdf/renderer";
import { cartDetailsContext } from "components/CartContext";
import Checkbox from "components/Checkbox";
import Spinner from "components/Spinner";
import { StyledSpinner } from "components/CartPageBottom/styles";
import { StyledLink, StyledButton, StyledDiv } from "./styles";
import { QuestionListPDF } from "./QuestionListPDF";

const PDFGenerator = () => {
  const { selectedQuestions } = useContext(cartDetailsContext);
  const [checked, setChecked] = useState(false);
  const handleCheck = () => {
    setChecked(!checked);
  };
  const [instance, updateInstance] = usePDF({
    document: (
      <QuestionListPDF
        questions={selectedQuestions}
        renderWithAnswers={checked}
      />
    ),
  });

  useEffect(() => {
    updateInstance();
    // eslint-disable-next-line
  }, [checked]);

  return (
    <StyledDiv>
      {instance.loading ? (
        <StyledSpinner>
          <Spinner size={50} />
        </StyledSpinner>
      ) : (
        <>
          <StyledLink href={instance.url || ""}>
            <StyledButton
              label="Eksportuj"
              kind="primary"
              rightIcon="rightArrow"
            ></StyledButton>
          </StyledLink>
        </>
      )}
      <Checkbox
        id="checkbox"
        name="checkbox"
        label="Eksportuj z odpowiedziami"
        isChecked={checked}
        onClick={handleCheck}
      />
    </StyledDiv>
  );
};
export default PDFGenerator;
