import React, { FC, useState } from "react";
import Container from "components/Container";
import Question from "components/Question";
import { CheckboxState } from "components/Question/types";
import Checkbox from "components/Checkbox";
import SortingArrow from "components/Icons/SortingArrow";
import {
  StyledCheckAll,
  StyledDeleteIcon,
  StyledDeleteQuestion,
  StyledQuestionHeader,
  StyledQuestionList,
  StyledSorting,
  StyledSortingArrows,
} from "./styles";
import { QuestionListProps } from "./types";
import { findIndex } from "./utils";

const QuestionList: FC<QuestionListProps> = ({
  questions,
  category,
  isInCart,
  selectedQuestions,
  setSelectedQuestions,
  setQuestions,
}) => {
  const [isSorted, setIsSorted] = useState(true);
  const filteredQuestions = questions.filter((q) => q.category === category);
  const areAllChecked = filteredQuestions.every((q) =>
    selectedQuestions.includes(q)
  );

  const toggleCheckbox: CheckboxState = (question) => {
    if (selectedQuestions.indexOf(question) >= 0) {
      const userLeftQuestions = selectedQuestions.filter(
        (selected) => selected !== question
      );
      setSelectedQuestions(userLeftQuestions);
    } else {
      const userSelectedQuestions = [...selectedQuestions, question];
      setSelectedQuestions(userSelectedQuestions);
    }
  };

  const toggleAllCheckboxes = () => {
    const selectedFromOtherCategories = selectedQuestions.filter(
      (q) => !filteredQuestions.includes(q)
    );

    if (areAllChecked) {
      setSelectedQuestions(selectedFromOtherCategories);
    } else {
      setSelectedQuestions([
        ...selectedFromOtherCategories,
        ...filteredQuestions,
      ]);
    }
  };

  const onSort = () => {
    setIsSorted(!isSorted);
  };

  const sortedQuestions = filteredQuestions.sort((a, b) => {
    const indexA = findIndex(a);
    const indexB = findIndex(b);
    if (indexA > indexB) {
      return isSorted ? 1 : -1;
    }
    if (indexA < indexB) {
      return isSorted ? -1 : 1;
    }

    return 0;
  });

  const selectedCartQuestions = selectedQuestions.filter(
    (q) => q.category === category
  );

  const deleteSelected = () => {
    setQuestions?.(
      questions.filter(
        (q) => !selectedQuestions.includes(q) || q.category !== category
      )
    );
    setSelectedQuestions(
      selectedQuestions.filter((q) => q.category !== category)
    );
  };

  return (
    <Container>
      <StyledQuestionList>
        {sortedQuestions.length > 0 && (
          <StyledQuestionHeader>
            <Checkbox
              id="toggleAll"
              name="toggleAll"
              isChecked={areAllChecked}
              onClick={toggleAllCheckboxes}
            />
            <StyledCheckAll onClick={toggleAllCheckboxes}>
              Zaznacz wszystko
            </StyledCheckAll>
            {isInCart && selectedCartQuestions.length > 0 && (
              <StyledDeleteQuestion onClick={deleteSelected}>
                <StyledDeleteIcon />
                Usuń zaznaczone
              </StyledDeleteQuestion>
            )}
            <StyledSorting onClick={onSort}>
              Sortowanie poziomu
              <StyledSortingArrows isSorted={isSorted}>
                <SortingArrow />
              </StyledSortingArrows>
            </StyledSorting>
          </StyledQuestionHeader>
        )}
        {sortedQuestions.map((question) => (
          <Question
            question={question}
            key={question.id}
            isChecked={selectedQuestions.indexOf(question) >= 0}
            onClick={toggleCheckbox}
          ></Question>
        ))}
      </StyledQuestionList>
    </Container>
  );
};

export default QuestionList;
