import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
import data from "data";
import Slider from "components/Slider";
import TechnologyList from "components/TechnologyList";
import QuestionList from "components/QuestionList";
import NavigationButtons from "components/NavigationButtons";
import { cartDetailsContext } from "components/CartContext";
import { getQuestions } from "api";
import Spinner from "components/Spinner";
import { Level, LevelName, Question, Technology, Category } from "types";
import { parseUrlFilters } from "./utils";

const categoryDict: Category[] = ["generic", "technology", "good-practices"];
const QuestionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    stepFromUrl,
    lvlRangeFromUrl,
    lvlRangeForSlider,
    techStackFromUrl,
    error,
  } = parseUrlFilters(searchParams);

  const [step, setStep] = useState(stepFromUrl);

  const [levelsRange, setLevelsRange] = useState<LevelName[]>(lvlRangeFromUrl);

  const [selectedTechnologies, setSelectedTechnologies] =
    useState<Technology["id"][]>(techStackFromUrl);

  const [value, setValue] = useState<readonly number[]>(lvlRangeForSlider);

  useEffect(() => {
    if (stepFromUrl !== step) {
      setStep(0);
    } else {
      setStep(stepFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepFromUrl]);

  useEffect(() => {
    if (error) {
      navigate("/404");
    } else {
      const techStack = selectedTechnologies.join(",");
      const lvlRange = levelsRange.join(",");
      setSearchParams({
        step: step.toString(),
        lvlRange: lvlRange,
        techStack: techStack,
      });
    }
  }, [
    setSearchParams,
    step,
    levelsRange,
    selectedTechnologies,
    navigate,
    error,
  ]);

  useEffect(() => {
    if (step !== 1) {
      return setSelectedTechnologies([]);
    }
  }, [step]);

  const { isLoading, data: questions } = useQuery("questions", getQuestions);
  const categoryFilter = (question: Question) => {
    return question.category === categoryDict[step];
  };

  const levelFilter = (question: Question): boolean => {
    const filteredLevel = question.levels.filter((lvl) =>
      levelsRange.includes(lvl.id)
    );
    return filteredLevel.length > 0;
  };

  const technologiesFilter = (question: Question) => {
    if (selectedTechnologies.length === 0 || step !== 1) {
      return true;
    }
    if (question.technology) {
      return selectedTechnologies.indexOf(question.technology.id) > -1;
    }
    return false;
  };

  const filteredQuestions = questions
    ?.filter(categoryFilter)
    .filter(levelFilter)
    .filter(technologiesFilter);

  const onChange = (newValue: number | readonly number[]) => {
    if (typeof newValue === "object") {
      setValue(newValue);
      onLevelsRangeChange(
        data.experienceLevels.slice(newValue[0], newValue[1] + 1)
      );
    }
  };

  const onLevelsRangeChange = (range: Level[]) => {
    const newRange = range.map((el) => el.id);
    setLevelsRange(newRange);
  };

  const onSelectedTechnologies = (tech: Technology["id"][]) => {
    setSelectedTechnologies(tech);
  };
  const { selectedQuestions, setSelectedQuestions } =
    useContext(cartDetailsContext);

  return error ? (
    <Navigate to="/404" />
  ) : (
    <div>
      <ProgressBar step={step} onClick={setStep} />
      <Slider value={value} onChange={onChange} />
      {step === 1 && (
        <TechnologyList
          selectedTechnologies={selectedTechnologies}
          onSelectedTechnology={onSelectedTechnologies}
        ></TechnologyList>
      )}
      <QuestionList
        questions={filteredQuestions || []}
        category={categoryDict[step]}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
        isInCart={false}
      />
      {isLoading && <Spinner />}
      <NavigationButtons step={step} onClick={setStep} />
    </div>
  );
};

export default QuestionPage;
