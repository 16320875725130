import React, { FC } from "react";
import { useNavigate } from "react-router";
import PDFGenerator from "components/PDFGenerator";
import Button from "components/Button";
import {
  StyledHeaderTag,
  StyledHeader,
  StyledSpan,
  StyledParagraph,
} from "./styles";
import { CartPageHeaderProps } from "./types";

const CartPageHeader: FC<CartPageHeaderProps> = ({ selectedQuestions }) => {
  const navigate = useNavigate();

  return (
    <StyledHeaderTag>
      {selectedQuestions.length > 0 ? (
        <>
          <StyledHeader>
            Twoja <StyledSpan>lista pytań</StyledSpan>
          </StyledHeader>
          <StyledParagraph>
            Możesz od razu eksportować listę lub sprawdzić zapisane poniżej
            pytania
          </StyledParagraph>
          <PDFGenerator />
        </>
      ) : (
        <>
          <StyledHeader>
            Twoja <StyledSpan>lista pytań</StyledSpan> jest pusta
          </StyledHeader>
          <Button
            label={"Edytuj listę"}
            kind={"alternative"}
            leftIcon="leftArrow"
            onClick={() => navigate("/?step=0")}
          />
        </>
      )}
    </StyledHeaderTag>
  );
};
export default CartPageHeader;
