import React, { FC } from "react";
import ModalIcon from "components/Modal/ModalIcons";
import BoyIcon from "components/Icons/BoyIcon";
import ModalTextMessage from "components/Modal/ModalTextMessage";
import ModalButtons from "components/Modal/ModalButtons";
import { StyledLink, StyledButton } from "components/Modal/styles";
import { ConfirmProps } from "./types";

const Confirm: FC<ConfirmProps> = ({ onConfirm, onCancel }) => {
  return (
    <div>
      <ModalIcon>
        <BoyIcon />
      </ModalIcon>
      <ModalTextMessage
        header="Czy dodać pytanie do bazy?"
        info="Sprawdziłeś pytanie przed dodaniem?"
      />
      <ModalButtons>
        <StyledLink to={"/add-new-question"}>
          <StyledButton
            kind="secondary"
            label="Ups, poprawiam"
            onClick={onCancel}
          />
        </StyledLink>
        <StyledButton kind="primary" label="Tak, dodaję" onClick={onConfirm} />
      </ModalButtons>
    </div>
  );
};

export default Confirm;
