import React, { FC } from "react";
import { IconProps } from "../types";

const SortingArrow: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="7"
    height="13"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.5 0L6.53109 5.25H0.468911L3.5 0Z" fill="#8C939B" />
    <path d="M3.5 13L0.468912 7.75L6.53109 7.75L3.5 13Z" fill="#DEE2E8" />
  </svg>
);

export default SortingArrow;
