import React, { FC } from "react";
import { ImgProps } from "components/Icons/types";
import SpinnerImg from "assets/Images/spinner.png";
import { StyledDiv, StyledImg } from "./styles";

const Spinner: FC<ImgProps> = ({ size }) => (
  <StyledDiv>
    <StyledImg size={size} src={SpinnerImg} alt="Spinning loader image" />
  </StyledDiv>
);

export default Spinner;
