import styled, { css } from "styled-components";
import { StyledButtonProps } from "./types";

export const StyledButton = styled.button<StyledButtonProps>`
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  height: 36px;
  margin: 5px;
  padding: 5px 18px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  letter-spacing: 0.055em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  :focus-visible {
    outline: none;
  }
  span {
    margin: 0 14px;
  }
  ${({ kind }) =>
    kind === "primary" &&
    css`
      background-color: ${({ theme }) => theme.colors.blue_400};
      color: white;

      svg path {
        fill: white;
      }

      &:hover {
      box-shadow: 0px 9px 12px -5px rgba(29, 116, 245, 0.25);
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray_200};
        box-shadow: none;
      }
    }
  `}
  ${({ kind }) =>
    kind === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.colors.blue_200};
      color: ${({ theme }) => theme.colors.blue_400};

      svg path {
        fill: ${({ theme }) => theme.colors.blue_400};
      }
      &:hover {
        box-shadow: 0px 9px 12px -5px rgba(29, 116, 245, 0.25);
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray_200};
        color: white;
        box-shadow: none;
        svg path {
          fill: white;
        }
      }
    `}
  ${({ kind }) =>
    kind === "warning" &&
    css`
      background-color: ${({ theme }) => theme.colors.red_400};
      color: white;

      &:hover {
        background: #fe5270;
        box-shadow: 0px 9px 12px -5px ${({ theme }) => theme.shadows.red_shadow};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray_200};
        box-shadow: none;
      }
    `}
  ${({ kind }) =>
    kind === "alternative" &&
    css`
      background-color: white;
      border: ${({ theme }) => theme.colors.blue_400};
      color: ${({ theme }) => theme.colors.blue_400};
      border: 2px solid ${({ theme }) => theme.colors.blue_400};

      svg path {
        fill: ${({ theme }) => theme.colors.blue_400};
      }

      &:hover {
        box-shadow: 0px 9px 12px -5px rgba(29, 116, 245, 0.25);
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.blue_200};
        color: ${({ theme }) => theme.colors.blue_400};
        box-shadow: none;
      }

      &:disabled,
      [disabled] {
        border: 2px solid ${({ theme }) => theme.colors.gray_200};
        color: ${({ theme }) => theme.colors.gray_200};
        background-color: white;
        box-shadow: none;
        svg path {
          fill: ${({ theme }) => theme.colors.gray_200};
        }
      }
    `}
  ${({ kind }) =>
    kind === "light" &&
    css`
      background-color: white;
      color: black;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 0.055em;
      padding: 10px;
      margin-right: 40px;
    `}
    span {
    margin: 0 10px;
  }
`;
