import React, { FC } from "react";
import { StyledModal } from "./styles";
import { ModalProps } from "./types";

const Modal: FC<ModalProps> = ({ isOpen, children, toggleModal }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      {children}
    </StyledModal>
  );
};

export default Modal;
