import { URLSearchParams } from "url";
import { LevelName, Technology } from "types";
import data from "data";

const levelNames: LevelName[] = [
  "entry",
  "junior",
  "middle",
  "senior",
  "expert",
];
const parseStep = (searchParams: URLSearchParams): number => {
  const step = searchParams.get("step");
  const parsedStep = step !== null ? parseInt(step, 10) : 0;
  if (parsedStep < 0 || parsedStep > 2 || isNaN(parsedStep)) {
    throw new Error();
  }
  return parsedStep;
};

const parseLevelRange = (searchParams: URLSearchParams): LevelName[] => {
  const lvlRange = searchParams.get("lvlRange");
  let parsedLvlRange: LevelName[] = [];
  if (lvlRange) {
    if (lvlRange.length > 0) {
      lvlRange.split(",").forEach((lvl) => {
        const singleLevel = levelNames.find((lvlname) => lvlname === lvl);
        if (singleLevel) {
          parsedLvlRange.push(singleLevel);
        } else {
          throw new Error();
        }
      });
    }
  } else {
    parsedLvlRange = levelNames;
  }
  return parsedLvlRange;
};

const parseSliderPosition = (
  parsedLvlRange: LevelName[]
): readonly number[] => {
  let sliderPositionFromUrl: readonly number[] = [];

  const leftMarkposition = levelNames.findIndex(
    (el) => el === parsedLvlRange[0]
  );
  const rightMarkPosition = levelNames.findIndex(
    (el) => el === parsedLvlRange[parsedLvlRange.length - 1]
  );
  if (leftMarkposition < 0 || rightMarkPosition < 0) {
    throw new Error();
  }
  sliderPositionFromUrl = [leftMarkposition, rightMarkPosition];
  return sliderPositionFromUrl;
};

const parseTechStack = (searchParams: URLSearchParams): Technology["id"][] => {
  const techStack = searchParams.get("techStack");
  let parsedTechStack: Technology["id"][] = [];
  if (techStack) {
    if (techStack.length > 0) {
      techStack.split(",").forEach((techName) => {
        const singleTech = data.technologies.find(
          (tech) => tech.id === techName
        );
        if (singleTech) {
          parsedTechStack.push(singleTech.id);
        } else {
          throw new Error();
        }
      });
    } else {
      parsedTechStack = [];
    }
  }
  return parsedTechStack;
};

export const parseUrlFilters = (searchParams: URLSearchParams) => {
  try {
    const error = false;
    const parsedStep: number = parseStep(searchParams);
    const parsedLvlRange: LevelName[] = parseLevelRange(searchParams);
    const parsedTechStack: Technology["id"][] = parseTechStack(searchParams);
    const sliderPositionFromUrl: readonly number[] =
      parseSliderPosition(parsedLvlRange);
    return {
      stepFromUrl: parsedStep,
      lvlRangeFromUrl: parsedLvlRange,
      lvlRangeForSlider: sliderPositionFromUrl,
      techStackFromUrl: parsedTechStack,
      error: error,
    };
  } catch (e) {
    return {
      stepFromUrl: 0,
      lvlRangeFromUrl: levelNames,
      lvlRangeForSlider: [0, 4],
      techStackFromUrl: [],
      error: true,
    };
  }
};
