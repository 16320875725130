import React, { FC } from "react";
import { IconProps } from "components/Icons/types";

const DownArrowIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00066 10L16 1.90438L14.118 -8.22666e-08L7.99534 6.19529L1.88204 -6.17116e-07L-8.3243e-08 1.90438L8.00066 10Z"
      fill="black"
    />
  </svg>
);

export default DownArrowIcon;
