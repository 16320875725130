import React, { FC } from "react";
import { IconProps } from "../types";

const Delete: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.11621 4.49994L0.0581055 7.55804L0.941989 8.44193L4.00009 5.38382L7.0582 8.44193L7.94208 7.55804L4.88398 4.49994L7.94199 1.44193L7.05811 0.558044L4.00009 3.61606L0.942083 0.558045L0.0581999 1.44193L3.11621 4.49994Z"
      fill="#DD3C58"
    />
  </svg>
);

export default Delete;
