import styled from "styled-components";
import Button from "components/Button";

export const StyledDiv = styled.div`
  margin: 0 0 33px 0;
  max-height: 89px;
  grid-column: 1/2;
`;

export const StyledButton = styled(Button)`
  margin: 14px 8px 0;
  padding: 0;
  width: 166px;
  &:nth-child(2) {
    margin-left: 0;
  }
`;

export const StyledParagraph = styled.p`
  margin-left: 12px;
  font-weight: 600;
  letter-spacing: 0.055em;
`;
