import React, { FC, useState, useEffect, useContext } from "react";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { cartDetailsContext } from "components/CartContext";
import Rethink from "./Rethink";
import Ready from "./Ready";
import { ModalDeleteListProps, ModalStep } from "./types";

const ModalDeleteList: FC<ModalDeleteListProps> = ({
  onToggle,
  onCancel,
  isOpen,
}) => {
  const [step, setStep] = useState<ModalStep>("confirm");
  const { setSelectedQuestions } = useContext(cartDetailsContext);

  useEffect(() => {
    if (step === "processing") {
      setTimeout(() => setStep("completed"), 1500);
      return;
    }
  }, [step]);

  const handleToggle = () => {
    if (step !== "processing") {
      onToggle();
    }
  };

  const handleConfirm = () => {
    setStep("processing");
    setSelectedQuestions([]);
    setStep("completed");
  };

  return (
    <Modal isOpen={isOpen} toggleModal={handleToggle}>
      {step === "confirm" && (
        <Rethink onConfirm={handleConfirm} onCancel={onCancel} />
      )}
      {step === "processing" && <Spinner />}
      {step === "completed" && <Ready onReady={handleToggle} />}
    </Modal>
  );
};

export default ModalDeleteList;
