import React, { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import DownArrowIcon from "components/Icons/DownArrow";
import Tag from "components/Tag";
import TechnologyTag from "components/TechnologyTag";
import Checkbox from "components/Checkbox";
import {
  StyledAnswer,
  StyledAnswerHeader,
  StyledAnswerWrapper,
  StyledCheckContainer,
  StyledDownArrow,
  StyledMainContainer,
  StyledQuestion,
  StyledTagContainer,
} from "./styles";
import { QuestionProps } from "./types";

const Question: FC<QuestionProps> = ({ question, isChecked, onClick }) => {
  const { title, answer, levels, technology } = question;
  const [isOpen, setIsOpen] = useState(false);
  const onCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(question);
  };

  return (
    <StyledMainContainer>
      <div onClick={() => setIsOpen(!isOpen)}>
        <StyledQuestion isOpen={isOpen}>
          <StyledCheckContainer>
            <Checkbox
              id={question.id}
              name={question.id}
              isChecked={isChecked}
              onClick={onCheckboxClick}
            />
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{title}</ReactMarkdown>
          </StyledCheckContainer>
          <StyledTagContainer>
            {levels.map((level) => (
              <Tag level={level} key={level.id} />
            ))}
            {technology && <TechnologyTag technology={technology} />}
            <div onClick={() => setIsOpen(!isOpen)}>
              <StyledDownArrow isOpen={isOpen}>
                <DownArrowIcon />
              </StyledDownArrow>
            </div>
          </StyledTagContainer>
        </StyledQuestion>
        <StyledAnswerWrapper isOpen={isOpen}>
          <StyledAnswer>
            <StyledAnswerHeader>Odpowiedź:</StyledAnswerHeader>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                code({ inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      style={darcula}
                      language={match[1]}
                      {...props}
                    >
                      {children}
                    </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {answer}
            </ReactMarkdown>
          </StyledAnswer>
        </StyledAnswerWrapper>
      </div>
    </StyledMainContainer>
  );
};

export default Question;
