import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Container from "components/Container";
import QuestionList from "components/QuestionList";
import { cartDetailsContext } from "components/CartContext";
import Button from "components/Button";
import { Question } from "types";
import {
  StyledListHeader,
  StyledEditButtonDisplay,
  StyledSection,
} from "./styles";

const CartQuestionList = () => {
  const { selectedQuestions: questions, setSelectedQuestions: setQuestions } =
    useContext(cartDetailsContext);
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  const navigate = useNavigate();
  return (
    <Container>
      <StyledSection>
        <StyledListHeader>Pytania ogólne</StyledListHeader>
        <QuestionList
          questions={questions}
          category={"generic"}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          isInCart={true}
          setQuestions={setQuestions}
        />
        <StyledEditButtonDisplay>
          <Button
            label={"Edytuj listę"}
            kind={"alternative"}
            leftIcon="leftArrow"
            onClick={() => navigate("/?step=0")}
          />
        </StyledEditButtonDisplay>
        <StyledListHeader>Technologie</StyledListHeader>
        <QuestionList
          questions={questions}
          category={"technology"}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          isInCart={true}
          setQuestions={setQuestions}
        />
        <StyledEditButtonDisplay>
          <Button
            label={"Edytuj listę"}
            kind={"alternative"}
            leftIcon="leftArrow"
            onClick={() => navigate("/?step=1")}
          />
        </StyledEditButtonDisplay>
        <StyledListHeader>Dobre praktyki</StyledListHeader>
        <QuestionList
          questions={questions}
          category="good-practices"
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          isInCart={true}
          setQuestions={setQuestions}
        />
        <StyledEditButtonDisplay>
          <Button
            label={"Edytuj listę"}
            kind={"alternative"}
            leftIcon="leftArrow"
            onClick={() => navigate("/?step=2")}
          />
        </StyledEditButtonDisplay>
      </StyledSection>
    </Container>
  );
};

export default CartQuestionList;
