import React from "react";
import { Text, View, Image, Link } from "@react-pdf/renderer";
import { styles } from "./styles";
import {
  ListItemProps,
  PDFMarkdownComponentProps,
  LinkElementProps,
  ImageElementProps,
} from "./types";

export const BoldText = ({ children }: PDFMarkdownComponentProps) => {
  return <Text style={styles.boldText}>{children}</Text>;
};

export const ItalicText = ({ children }: PDFMarkdownComponentProps) => {
  return <Text style={styles.italicText}>{children}</Text>;
};

export const LineThoughText = ({ children }: PDFMarkdownComponentProps) => {
  return <Text style={styles.linethroughText}>{children}</Text>;
};

export const QuoteText = ({ children }: PDFMarkdownComponentProps) => {
  return <Text style={styles.quoteText}>{children}</Text>;
};

export const OrderedList = ({ children }: PDFMarkdownComponentProps) => {
  return <View>{children}</View>;
};

export const UnorderedList = ({ children }: PDFMarkdownComponentProps) => {
  return <View>{children}</View>;
};

export const ListItem = ({ children, index, ordered }: ListItemProps) => {
  const ulBullet = "•";
  return (
    <Text style={styles.listItem}>
      {ordered ? index + 1 + ". " + children : ulBullet + " " + children}
    </Text>
  );
};

export const ImageElement = ({ src }: ImageElementProps) => {
  return <Image style={styles.imageElement} src={src} />;
};

export const PreElement = ({ children }: PDFMarkdownComponentProps) => {
  return <Text style={styles.preElement}>{children}</Text>;
};

export const LinkElement = ({ children, href }: LinkElementProps) => {
  return (
    <Link style={styles.linkElement} src={href ? href : "#"}>
      {children}
    </Link>
  );
};
