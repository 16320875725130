import styled from "styled-components";

export const StyledTrack = styled.div<{ index: number }>`
  background: ${(props) =>
    props.index === 1
      ? ({ theme }) => theme.colors.blue_200
      : ({ theme }) => theme.colors.gray_200};
  height: ${(props) => (props.index === 1 ? "8px" : "4px")};
  margin-top: ${(props) => (props.index === 1 ? "none" : "2px")};
`;
