import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledTopMenu = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StyledDiv = styled.div`
  margin: 0 13%;
  width: 100%;
  max-width: 1278px;
  height: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLogo = styled(Link)`
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  span {
    color: ${({ theme }) => theme.colors.blue_400};
  }
  span:nth-child(2) {
    font-weight: 600;
    font-size: 9px;
    color: ${({ theme }) => theme.colors.gray_400};
  }
`;

export const StyledSearch = styled.input`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.blue_400};
  box-sizing: border-box;
  border-radius: 14px;
  width: 271px;
  height: 36px;
  padding: 12px;
  margin-right: 40px;
  &:focus-visible {
    outline: none;
  }
  ::placeholder {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.055em;
    color: ${({ theme }) => theme.colors.gray_400};
    &:focus-visible {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const StyledRightMenu = styled.section`
  display: inline-flex;
  align-items: center;
  margin: 25px 5px 25px 5px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
