import React, { useContext } from "react";
import ListIcon from "components/Icons/ListIcon";
import { cartDetailsContext } from "components/CartContext";
import { StyledCartButton, StyledCounter, StyledLabel } from "./styles";

const CartListButton = () => {
  const { selectedQuestions } = useContext(cartDetailsContext);
  return (
    <StyledCartButton>
      <ListIcon />
      <StyledLabel>Twoja lista</StyledLabel>
      {selectedQuestions.length > 0 && (
        <StyledCounter>{selectedQuestions.length}</StyledCounter>
      )}
    </StyledCartButton>
  );
};

export default CartListButton;
