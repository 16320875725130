import styled from "styled-components";
import { ImgProps } from "components/Icons/types";

export const StyledImg = styled.img<ImgProps>`
  width: ${({ size }) => size + "px"};
  height: ${({ size }) => size + "px"};
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

export const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
