import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    gray_100: "#F0F0F0",
    gray_200: "#DBDBDB",
    gray_300: "#DEE2E8",
    gray_400: "#6B6B6B",
    gray_500: "#8C939B",
    gray_600: "#262626",
    gray_700: "#2D364A",
    blue_100: "#F6F9FF",
    blue_200: "#BBD6FF",
    blue_300: "#76D3E0",
    blue_400: "#1D74F5",
    blue_500: "#0500FF",
    red_200: "#FF687D",
    red_300: "#FE5270",
    red_400: "#DD3C58",
    red_500: "#C82F49",
    green_100: "#F8FCFA",
    green_400: "#4EBE8C",
    black: "#000000",
    violet: "#AB87BC",
    yellow: "#F5D562",
    orange: "#F4A223",
    white: "#FFFFFF",
    darkBlueModal: "rgba(9, 38, 82, 0.25)", // Use this for modals overlay as well as for modal box-shadow.
  },
  shadows: {
    red_shadow: "rgba(128, 4, 26, 0.21)",
    blue_shadow: "rgba(29, 116, 245, 0.25)",
  },
  fonts: {
    primaryFontFamily: '"Inter", sans-serif;',
    secondaryFontFamily: '"Poppins", sans-serif;',
    tertiaryFontFamily: '"Source Code Pro", monospace;',
  },
};

export default theme;
