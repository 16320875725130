import styled from "styled-components";
import Button from "components/Button";

export const StyledCartButtons = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    align-items: flex-end;
  }
`;

export const StyledSpinner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const StyledExportDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const StyledButton = styled(Button)`
  width: 166px;
  height: 48px;
  margin: 0 0 20px 0;
  padding: 10px 5px;
  font-size: 20px;
`;
