import React, { useState } from "react";
import Button from "components/Button";
import ModalDeleteList from "components/ModalDeleteList";
import Container from "components/Container";
import PDFGenerator from "components/PDFGenerator";
import { StyledCartButtons } from "./styles";

const CartPageBottom = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    handleToggle();
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container>
      <StyledCartButtons>
        <Button
          label="Usuń całą listę"
          kind="warning"
          leftIcon="deleteIcon"
          onClick={handleToggle}
        />
        <ModalDeleteList
          isOpen={isOpen}
          onCancel={handleCancel}
          onToggle={handleToggle}
        />

        <PDFGenerator />
      </StyledCartButtons>
    </Container>
  );
};

export default CartPageBottom;
