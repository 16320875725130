import styled from "styled-components";
import Check from "components/Icons/Check";

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledLabel = styled.label`
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_400};
  transform-origin: center;
  cursor: pointer;
`;

export const StyledCheck = styled(Check)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-40%);
  pointer-events: none;
`;

export const StyledCheckbox = styled.input`
  appearance: none;
  margin: 0 13px;
  width: 18px;
  height: 18px;
  padding: 0;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray_400};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.blue_400};
  }
  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.gray_200};
    background: ${({ theme }) => theme.colors.gray_300};
  }
  &:disabled::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 1px;
    background: white;
    transform: translate(-50%);
  }
`;
