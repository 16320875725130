import React from "react";
import data from "data";
import { StyledLegend, StyledStep } from "./styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Legend = (props: any) => {
  return (
    <StyledLegend {...props}>
      {data.experienceLevels.map((level, index) => {
        const isActive = index >= props.value[0] && index <= props.value[1];
        return (
          <StyledStep key={level.id} isActive={isActive}>
            {level.label}
          </StyledStep>
        );
      })}
    </StyledLegend>
  );
};

export default Legend;
