import styled from "styled-components";
import DownArrowIcon from "components/Icons/DownArrow";
import theme from "theme";

export const StyledQuestion = styled.div<{ isOpen: boolean }>`
  background-color: ${(props) => {
    return props.isOpen
      ? ({ theme }) => theme.colors.blue_100
      : theme.colors.white;
  }};
  color: ${({ theme }) => theme.colors.black};
  margin: -1px 20px;
  padding: 18px 25px 18px 18px;
  min-height: 60px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray_200};
  border-top: solid 1px ${({ theme }) => theme.colors.gray_200};
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  p {
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0.055em;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue_100};
  }
`;

export const StyledCheckContainer = styled.section`
  text-align: left;
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 18px;
`;

export const StyledTagContainer = styled.section`
  text-align: right;
  display: flex;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  ul {
    list-style: inside;
  }
  ol {
    list-style: auto;
    list-style-position: inside;
  }
  pre {
    border-radius: 13px;
  }
`;

export const StyledAnswerWrapper = styled.div<{ isOpen: boolean }>`
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.055em;
  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  height: auto;
`;

export const StyledAnswer = styled.div`
  margin: 42px 52px 59px 54px;
`;

export const StyledAnswerHeader = styled.div`
  color: ${({ theme }) => theme.colors.blue_400};
  font-family: ${({ theme }) => theme.fonts.primaryFontFamily};
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.055em;
  margin: 16px 0 22px 0;
`;

export const StyledDownArrow = styled(DownArrowIcon)<{ isOpen: boolean }>`
  transform: ${(props) => (props.isOpen ? "rotate(-180deg)" : "none")};
  transition: 0.25s;
`;
