import React, { FC } from "react";
import { StyledTag } from "./styles";
import { TagProps } from "./types";

const Tag: FC<TagProps> = ({ level }) => {
  return (
    <StyledTag color={level.color} onClick={(e) => e.stopPropagation()}>
      {level.label}
    </StyledTag>
  );
};

export default Tag;
